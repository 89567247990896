import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Course from "src/models/analitics/Course";
import {
  TopicResult,
  UserAnalytic,
} from "src/models/analitics/LocationAnalytics";
import { UserRoles } from "src/models/profile/UserRoles";
import ProfileService from "src/services/ProfileService";
import HandleEvents from "../ui/HandleEvents";

interface PersonTableProps {
  selectedUser: UserAnalytic;
  courseAnalytics: Course[];
  name: string;
  buttonClick: (courseId: number, topicId: number) => void;
  noteButtonClick: (topicId: number, userId: number) => void;
}

const PersonTable: FC<PersonTableProps> = ({
  selectedUser,
  courseAnalytics,
  name,
  buttonClick,
  noteButtonClick,
}) => {
  const { profile } = ProfileService;

  console.log(selectedUser, name, courseAnalytics);

  // Проверяем, существует ли analyticsData и его необходимые поля
  const courses: Course =
    courseAnalytics.find((course) => course.name == name) || ({} as Course);

  // Проверяем, существует ли selectuser и его поле topics_result
  const dataResult =
    selectedUser && selectedUser.topics_result
      ? [
          ...selectedUser.topics_result.filter(
            (result: TopicResult) => result.course_id == courses.id
          ),
        ]
      : [];

  // Функция для обработки клика на кнопку "Подробнее"
  const handleButtonClick = (courseId: number, topicId: number) => {
    // Вызываем функцию togglePopup и передаем ей topicId
    buttonClick(courseId, topicId);
  };

  const handleNoteButtonClick = (courseId: number, topicId: number) => {
    // Вызываем функцию togglePopup и передаем ей topicId
    noteButtonClick(courseId, topicId);
  };

  return (
    <table className="table mb-0">
      <thead>
        <tr>
          <th className="text-center ">{name}</th>
          <th className="text-center">Просмотр</th>
          <th className="text-center">Выполнен</th>
          <th className="text-center">Действия</th>
          {(profile?.role === UserRoles.TEACHER ||
            profile?.role === UserRoles.DIRECTOR) && (
            <th className="text-center"></th>
          )}
        </tr>
      </thead>
      <tbody>
        {dataResult.map((item, index) => (
          <tr key={index} className="">
            <td className="text-center align-middle py-3">
              {/* Добавляем проверку на существование courses и его поля topics */}
              {courses && courses.topics
                ? courses.topics.find((topic) => topic.id === item.topic_id)
                    ?.name || null
                : null}
            </td>
            <td className="text-center py-3">
              <HandleEvents data={item.readed} />
            </td>
            <td className="text-center py-3">
              <HandleEvents data={item.completed} />
            </td>
            <td className="text-center ">
              <button
                onClick={() => handleButtonClick(courses.id, item.topic_id)}
                type="button"
                className="btn btn-outline-secondary waves-effect waves-light mt-2"
              >
                Подробнее
              </button>
            </td>
            {(profile?.role === UserRoles.TEACHER ||
              profile?.role === UserRoles.DIRECTOR) && (
              <td className="text-center ">
                <button
                  onClick={() =>
                    handleNoteButtonClick(item.topic_id, selectedUser.id)
                  }
                  type="button"
                  className="btn btn-danger waves-effect waves-light mt-2"
                >
                  Жалоба
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(PersonTable);
