import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { AppCheckbox } from "src/components/Common/AppCheckbox";
import { AppDropZone } from "src/components/Common/AppDropZone";
import { EditButton } from "src/components/Common/EditButton";
import { AppModal } from "src/components/Common/Modal";
import styles from "../../UserWizard.module.scss";

interface UserDocument {
  name: string;
  url: string;
  isAccepted: boolean;
}

interface DocumentsCardProps {}

const DocumentsCard: FC<DocumentsCardProps> = ({}) => {
  const [docModal, setDocModal] = useState(false);
  const [docs, setDocs] = useState<UserDocument[]>([
    {
      name: "Согласие на обработку персональных данных #55864",
      url: "https://google.com",
      isAccepted: true,
    },
    {
      name: "Свидетельство о рождении #55865",
      url: "https://google.com",
      isAccepted: false,
    },
    {
      name: "Паспорт #55866",
      url: "https://google.com",
      isAccepted: false,
    },
  ]);
  return (
    <Card>
      <CardTitle className="font-weight-bold p-2">Документы</CardTitle>
      <CardBody>
        {docs.map((doc, index) => (
          <Row key={index}>
            <Col xl={10}>
              {doc.isAccepted ? (
                <a target="_blank" href={doc.url} rel="noreferrer">
                  {doc.name}
                </a>
              ) : (
                doc.name
              )}
            </Col>
            <Col xl={1}>
              {doc.isAccepted ? (
                <AppCheckbox
                  onClick={() => {}}
                  isDisabled
                  isChecked={doc.isAccepted}
                />
              ) : (
                <span className={styles.docWarning}>!</span>
              )}
            </Col>
            <Col xl={1}>
              <EditButton
                onClick={() => {
                  setDocModal(true);
                }}
              />
            </Col>
          </Row>
        ))}
      </CardBody>
      <AppModal
        withButtons={false}
        onClose={() => setDocModal(false)}
        isOpen={docModal}
      >
        <AppDropZone onUpload={() => setDocModal(false)} />
      </AppModal>
    </Card>
  );
};
export default observer(DocumentsCard);
