import axios, { AxiosRequestConfig } from "axios";

const config: AxiosRequestConfig = {
  baseURL: "https://lk.bbnkl.ru/api.n",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    AppVersion: process.env.REACT_APP_VERSION,
    AppClient: "ANDROID",
  },
};

const api = axios.create(config);
api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});
api.interceptors.response.use(
  function (response) {
    // Любые коды статуса, не попадающие в диапазон 2xx, вызывают эту функцию
    return response.data ? response.data : response;
  },
  function (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Внутренняя ошибка сервера";
        break;
      case 401:
        message = "Недопустимые учетные данные";
        break;
      case 404:
        message = "Извините! запрошенные данные не найдены";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error === "Request failed with status code 401") {
      localStorage.removeItem("token");
    }
  }
);

export default api;
