import React, { FC, useCallback, useEffect } from "react";
import Dropzone from "react-dropzone";
import styles from "./AppDropZone.module.scss";

interface AppDropZoneProps {
  title?: string;
  subtitle?: string;
  onUpload?: (file: File[]) => void;
}

const AppDropZone: FC<AppDropZoneProps> = ({ title, subtitle, onUpload }) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const handleAddFiles = useCallback((acceptedFiles: File[]) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const handleDelete = useCallback((file: File) => {
    setFiles((prev) => prev.filter((item) => item !== file));
  }, []);

  const handleUpload = useCallback(() => {
    onUpload?.(files);
    setFiles([]);
  }, [onUpload, files]);

  useEffect(() => {
    return () => setFiles([]);
  }, []);
  return (
    <div>
      <h4 className="card-title">{title}</h4>
      <p className="card-title-desc">{subtitle}</p>

      <Dropzone onDrop={handleAddFiles}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={"dropzone cursor-pointer"}>
            <div className="fallback">
              <input {...getInputProps()} />
            </div>
            <div className="dz-message needsclick text-center">
              <div className="mb-3">
                <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
              </div>

              <h4>Перетащите сюда файлы или нажмите для выбора файла</h4>
            </div>
          </div>
        )}
      </Dropzone>
      {files.length > 0 && (
        <div className="mt-3">
          <div className={styles.filesWrapper}>
            {files.map((file, index) => (
              <div
                onClick={() => handleDelete(file)}
                key={index}
                className={styles.item}
              >
                <div className={styles.delFile}>
                  <i className="ri ri-delete-bin-line"></i>
                </div>
                <span> {file.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="text-center mt-4">
        <button
          onClick={handleUpload}
          type="button"
          className={
            "btn waves-effect waves-light " +
            (files.length > 0 ? "btn-primary" : "btn-secondary")
          }
        >
          Загрузить файлы
        </button>
      </div>
    </div>
  );
};
export default AppDropZone;
