import classnames from "classnames";
import React, { FC } from "react";
import { PeriodNames } from "src/models/dashboard/IDashboard";

interface ButtonSelectorProps {
  isActive?: boolean;
  label: string;
  value: PeriodNames;
  onClick: (value: PeriodNames) => void;
}

const ButtonSelector: FC<ButtonSelectorProps> = ({
  isActive = false,
  label,
  onClick,
  value,
}) => {
  return (
    <div>
      <button
        onClick={() => onClick(value)}
        type="button"
        className={classnames(["btn btn-sm"], {
          "btn-subtle-primary": isActive,
          "btn-subtle-secondary": !isActive,
        })}
      >
        {label}
      </button>
    </div>
  );
};
export default ButtonSelector;
