import { User, UserStatus } from "src/models/profile/User";
import { UserRoles } from "src/models/profile/UserRoles";

export const _USERS_LIST_CITIES = [
  { label: "Moscow", value: "moscow" },
  { label: "Kazan", value: "kazan" },
  { label: "Omsk", value: "omsk" },
  { label: "Nizhny Novgorod", value: "nizhny-novgorod" },
  { label: "Rostov", value: "rostov" },
];

export const _USERS_MOCK_DATA: User[] = [
  {
    id: 1,
    name: "Евгений",
    phone: "+7 999 999 99 99",
    role: UserRoles.SUPERADMIN,
    status: UserStatus.APPROVED,
    locked: 0,
    balance: 0,
    timezone: "",
    can_use_promocode: false,
    showTeacherBounty: false,
    demo: false,
    watermark: "",
    yandexCards: [],
    trial: 0,
    tariff: "Free",
    isAutoPayment: true,
    locations: [
      {
        id: 1,
        name: "Moscow",
        isMain: true,
        isActive: true,
      },
      {
        id: 2,
        name: "Kazan",
        isMain: false,
        isActive: false,
      },
    ],
    restOfDays: 20,
    isUnlimited: false,
    created_at: "2022-05-31T17:30:00.000000Z",
    updated_at: "2022-06-10T17:30:00.000000Z",
  },
  {
    id: 2,
    name: "Андрей",
    phone: "+7 999 999 99 99",
    role: UserRoles.SUPERADMIN,
    status: UserStatus.REGISTRATION,
    locked: 0,
    balance: 230,
    timezone: "",
    can_use_promocode: false,
    showTeacherBounty: false,
    demo: false,
    watermark: "",
    yandexCards: [],
    trial: 0,
    tariff: "Base",
    isAutoPayment: false,
    locations: [
      {
        id: 1,
        name: "Moscow",
        isMain: false,
        isActive: true,
      },
      {
        id: 2,
        name: "Kazan",
        isMain: true,
        isActive: true,
      },
    ],
    restOfDays: 0,
    isUnlimited: true,
    created_at: "2022-05-31T17:30:00.000000Z",
    updated_at: "",
  },
];
