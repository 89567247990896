export enum UserRoles {
  ACCOUNTANT = "ACCOUNTANT",
  ADMINISTRATOR = "ADMINISTRATOR",
  CLIENT = "CLIENT",
  DEMOUSER = "DEMOUSER",
  DEVELOPER = "DEVELOPER",
  DIRECTOR = "DIRECTOR",
  INSTALLER = "INSTALLER",
  MANAGER = "MANAGER",
  NOLOCATIONUSER = "NOLOCATIONUSER",
  OPERATOR = "OPERATOR",
  STUFF = "STUFF",
  SUBSCRIBER = "SUBSCRIBER",
  SUPERADMIN = "SUPERADMIN",
  TEACHER = "TEACHER",
}
