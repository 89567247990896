import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import styles from "./ModalMenu.module.scss";
export interface MenuItem {
  label: string;
  value: string;
}
interface ModalMenuProps {
  options?: MenuItem[];
  filter: FilterModel;
  onChange?: (selectedOptions: OptionModel[]) => void;
}

const ModalMenu: FC<ModalMenuProps> = ({ options, onChange, filter }) => {
  return (
    <>
      <div className={styles.wrapper} />
      <div
        className="dropdown-menu dt-button-collection"
        style={{ display: "block", top: "38px", position: "absolute" }}
        aria-modal="true"
        role="dialog"
      >
        <div role="menu">
          {filter.options.map((item, index) => (
            <span
              onClick={(e) => {
                e.stopPropagation();
                filter.onSelect(item);
              }}
              className={classNames([
                "dropdown-item",
                styles.dropdownItem,
                { [styles.isSelected]: item.isSelected },
              ])}
              tabIndex={0}
              aria-controls="datatable-buttons"
              data-dt-idx={index}
              key={index}
            >
              <span>{item.label}</span>
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
export default observer(ModalMenu);
