import React, { FC } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getTimeFromSeconds } from "src/helpers/getTimeFromSeconds";
import { IActiveUsers } from "src/models/dashboard/IDashboard";
import ValueItem from "./ValueItem";

interface UsersActivityChartProps {
  activeUsers: IActiveUsers;
}

const __MAX_VALUE = 1000;

const getPercent = (value: number, maxValue = __MAX_VALUE) => {
  return Math.round((value / maxValue) * 100);
};

let options: ApexCharts.ApexOptions = {
  chart: {
    height: 350,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 10,
        size: "45%",
      },
      track: {
        show: true,
        strokeWidth: "80%",
        margin: 12,
      },

      dataLabels: {
        name: {
          fontSize: "27px",
        },
        value: {
          show: true,
          fontSize: "18px",
          fontFamily: undefined,
          fontWeight: 400,
          color: undefined,
          offsetY: 16,
          formatter: function (val) {
            return val + "% от макс.";
          },
        },
      },
    },
  },
  labels: ["Активны за сегодня", "Активны сейчас", "Время в сети"],
  colors: ["#099680", "#4aa3ff", "#5664d2"],
};

const UsersActivityChart: FC<UsersActivityChartProps> = ({ activeUsers }) => {
  return (
    <Col xl={4}>
      <Card className="card-height-100">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <CardTitle>Активность пользователей</CardTitle>
            </div>
            <div className="flex-shrink-0">
              {/* <select
                defaultValue={"AP"}
                className="form-select form-select-sm mb-0 my-n1"
              >
                <option value="MAY">May</option>
                <option value="AP">April</option>
                <option value="MA">March</option>
                <option value="FE">February</option>
                <option value="JA">January</option>
                <option value="DE">December</option>
              </select> */}
            </div>
          </div>
          <ReactApexChart
            key={activeUsers.now}
            options={{
              ...options,
              plotOptions: {
                ...options.plotOptions,
                radialBar: {
                  ...options.plotOptions?.radialBar,
                  dataLabels: {
                    ...options.plotOptions?.radialBar?.dataLabels,

                    total: {
                      show: true,
                      label: "Активны сейчас",
                      color: "#4aa3ff",
                      formatter: function (w) {
                        return `${activeUsers.now} чел.`;
                      },
                    },
                  },
                },
              },
            }}
            type="radialBar"
            height={350}
            series={[
              getPercent(activeUsers.today, activeUsers.today_max || 0),
              getPercent(activeUsers.now, activeUsers.now_max || 0),
              getPercent(activeUsers.time_avg, activeUsers.time_max || 0),
            ]}
          />
          <Row>
            <ValueItem
              color="#099680"
              label={"Активны за сегодня"}
              icon={"mdi mdi-account-group"}
              value={activeUsers.today}
              valueLabel={"чел."}
            />
            <ValueItem
              color="#4aa3ff"
              label={"Активны сейчас"}
              icon={"mdi mdi-account-convert"}
              value={activeUsers.now}
              valueLabel={"чел."}
            />
            <ValueItem
              color="#5664d2"
              label={"Время в сети"}
              icon={"mdi mdi-account-clock"}
              value={getTimeFromSeconds(activeUsers.time_avg)}
              valueLabel={""}
            />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
export default UsersActivityChart;
