import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import LocationAnalytics, {
  GroupAnalytic,
} from "src/models/analitics/LocationAnalytics";
import LocationsService from "src/services/LocationsService";
import { useFilter } from "../../Hooks/useFilter";
import usePagination from "../../Hooks/usePagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MyLoderChart from "../../components/Common/Loader/MyLoderChart";
import MyFilter from "../../components/Common/MyFilter/MyFilter";
import Progressbar from "../../components/Common/Progressbar/Progressbar";
import RadialChart1 from "../../components/Common/chartjs/RadialChart1";
import Pagination from "../../components/Common/ui/Pagination";
import AnalyticsViewModel from "./AnalyticsViewModel";

const Cameras: FC = () => {
  const {
    locationAnalytics,
    isLoading,
    analyticsData,
    isError,
    getAnalyticsData,
    courseAnalytics,
  } = AnalyticsViewModel;
  const params = useParams();
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filter, setFilter] = useState({ sort: "", query: "" });
  const { locations, getLocations } = LocationsService;

  const selectedData = useMemo(() => {
    if (locationAnalytics.length) {
      return toJS(locationAnalytics).find(
        // @ts-ignore
        (data: LocationAnalytics) => data.id == params.id
      );
    }
  }, [params.id, locationAnalytics]);

  const sortedAndSearchedCharts = useFilter(
    selectedData?.groups || [],
    filter.sort,
    filter.query
  );

  useEffect(() => {
    if (locationAnalytics.length === 0 && !isLoading) {
      getAnalyticsData();
    }
    if (!locations.length) {
      // getLocations();
    }
  }, []);

  const options = [
    { value: "name", name: "По названию" },
    { value: "loyalty", name: "По лояльности" },
  ];

  const { currentPage, totalPages, handlePageChange } = usePagination(
    sortedAndSearchedCharts.length,
    itemsPerPage
  );

  // Brandcrumb
  const links = useMemo(() => {
    return [
      { path: "/", label: "Главная" },
      { path: "/analytics", label: "Аналитика" },
      { label: selectedData?.name },
    ];
  }, [selectedData?.name]);

  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (!searchParams.get("page")) return;
    handlePageChange(searchParams.get("page") || "1");
  }, [handlePageChange, searchParams]);
  document.title = `Аналитика | ${selectedData?.name}`;

  return (
    <React.Fragment>
      <div className="page-content d-flex flex-column mb-4">
        <Container fluid={true}>
          <Breadcrumbs links={links} />
          <Row>
            {isLoading ? (
              [...Array(6)].map((_, index) => (
                <Col key={index} xl={4} lg={6} md={6}>
                  <MyLoderChart />
                </Col>
              ))
            ) : isError ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных аналитики</Alert>
              </Col>
            ) : (
              <div
                className="flex-grow-1 d-flex flex-column"
                style={{ minHeight: "85vh" }}
              >
                <MyFilter
                  filter={filter}
                  setFilter={setFilter}
                  options={options}
                  pageValue={itemsPerPage}
                  onChangePage={(value: number) => setItemsPerPage(value)}
                />
                <Row>
                  {selectedData && (
                    <RadialChart1 loyalty={selectedData.loyalty} />
                  )}
                  {selectedData &&
                    selectedData.courses_result.map(
                      (
                        result: {} | undefined,
                        index: React.Key | null | undefined
                      ) => (
                        <RadialChart1
                          key={index}
                          result={result}
                          analyticsData={courseAnalytics}
                        />
                      )
                    )}
                </Row>
                {sortedAndSearchedCharts.length === 0 ? (
                  <h3>Ничего не найдено!</h3>
                ) : (
                  <Row className="mt-4">
                    {sortedAndSearchedCharts
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((group: GroupAnalytic, index: number) => (
                        <Progressbar
                          key={index}
                          group={group}
                          data={analyticsData}
                        />
                      ))}
                  </Row>
                )}
                <div className="mt-auto">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default observer(Cameras);
