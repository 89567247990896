//Файл src/helpers/url_helper.js содержит URL всех API модулей.

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/auth/login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//LOGOUT
export const DELETE_FAKE_JWT_LOGIN = "/auth/session";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//моя интеграция
export const GET_POSTS_URL = "https://jsonplaceholder.typicode.com/posts";

//Аналитика
export const GET_ANALYTICS_URL = "/learning-courses/analytics";

//Камеры
export const GET_CAMERAS_URL = "/camera/locations";
