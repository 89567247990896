import React, { FC, memo } from "react";
import styles from "./EditButton.module.scss";

interface EditButtonProps {
  onClick: () => void;
}

const EditButton: FC<EditButtonProps> = ({ onClick }) => {
  return (
    <div className={styles.btn} onClick={onClick}>
      <i className="ri ri-pencil-line" />
    </div>
  );
};
export default memo(EditButton);
