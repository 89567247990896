import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { AppSwitch } from "src/components/Common/AppSwitch";
import { DeleteButton } from "src/components/Common/DeleteButton";
import { AppModal } from "src/components/Common/Modal";
import { UserLocation } from "src/models/profile/User";

const __MODAL_MESSAGES__ = {
  delete: "Вы уверены, что хотите удалить локацию?",
  disable: "Вы уверены, что хотите отключить локацию?",
  enable: "Вы уверены, что хотите включить локацию?",
};

interface UserLocationsCardProps {
  locations?: UserLocation[];
  deleteLocation?: (id: number) => void;
}

const UserLocationsCard: FC<UserLocationsCardProps> = ({
  locations,
  deleteLocation,
}) => {
  const modalQuestion = useRef<string>("");
  const activeLocation = useRef<UserLocation>();
  const [viewModal, setViewModal] = React.useState(false);

  const toggleEnableLocation = useCallback((location: UserLocation) => {
    activeLocation.current = location;
    setViewModal(true);

    if (location.isActive) {
      modalQuestion.current = __MODAL_MESSAGES__.disable;
    } else {
      modalQuestion.current = __MODAL_MESSAGES__.enable;
    }
  }, []);

  const handleDeleteLocation = useCallback((location: UserLocation) => {
    activeLocation.current = location;
    setViewModal(true);
    modalQuestion.current = __MODAL_MESSAGES__.delete;
  }, []);

  const onSubmitModal = useCallback(() => {
    runInAction(() => {
      if (modalQuestion.current === __MODAL_MESSAGES__.disable) {
        setViewModal(false);
        activeLocation &&
          activeLocation.current &&
          (activeLocation.current.isActive = false);
      }

      if (modalQuestion.current === __MODAL_MESSAGES__.enable) {
        setViewModal(false);
        activeLocation &&
          activeLocation.current &&
          (activeLocation.current.isActive = true);
      }

      if (modalQuestion.current === __MODAL_MESSAGES__.delete) {
        setViewModal(false);
        deleteLocation &&
          activeLocation.current &&
          deleteLocation(activeLocation.current.id);
      }
    });
  }, [deleteLocation]);

  if (!locations) return null;
  return (
    <Card title="Локации">
      <CardTitle className="font-weight-bold p-2">Локации</CardTitle>
      <CardBody>
        {locations.length === 0 && <div>У пользователя нет локаций</div>}
        {locations.map((location) => (
          <Row
            className="d-flex align-items-center justify-content-between"
            key={location.id}
          >
            <Col xl={8}>{location.name}</Col>
            <Col xl={2}>{location.isMain ? "Основная" : ""}</Col>
            <Col xl={1}>
              <AppSwitch
                isChecked={location.isActive}
                onClick={() => toggleEnableLocation(location)}
              />
            </Col>
            <Col xl={1}>
              <DeleteButton onClick={() => handleDeleteLocation(location)} />
            </Col>
          </Row>
        ))}
        <Row>
          <Col className="d-flex align-items-center justify-content-end mt-3">
            <button className="btn btn-primary">Добавить локацию</button>
          </Col>
        </Row>
      </CardBody>
      <AppModal
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        onSubmit={onSubmitModal}
        positiveButtonLabel="Да"
        negativeButtonLabel="Нет"
      >
        {modalQuestion.current}
      </AppModal>
    </Card>
  );
};
export default observer(UserLocationsCard);
