import classNames from "classnames";
import React, { FC, memo, useCallback } from "react";
import { Image } from "../Image";
import styles from "./VideoPreviewer.module.scss";

interface VideoPreviewerProps {
  url: string;
  onClick: () => void;
}

const VideoPreviewer: FC<VideoPreviewerProps> = ({ url, onClick }) => {
  const handlePress = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );
  return (
    <div className={styles.preview} onClick={handlePress}>
      <div className={styles.playWrapper}>
        <i
          style={{ fontSize: 40 }}
          className={classNames(["fas fa-play", styles.play])}
        ></i>
      </div>
      <Image url={url} />
    </div>
  );
};
export default memo(VideoPreviewer);
