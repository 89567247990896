import React, { FC, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MyFilter from "../../components/Common/MyFilter/MyFilter";
import RadialChart from "../../components/Common/chartjs/RadialChart";
import Pagination from "../../components/Common/ui/Pagination";

import { observer } from "mobx-react-lite";
import { useMediaQuery } from "react-responsive";
import LocationsService from "src/services/LocationsService";
import { useFilter } from "../../Hooks/useFilter";
import usePagination from "../../Hooks/usePagination";
import MyLoderChart from "../../components/Common/Loader/MyLoderChart";
import AnalyticsViewModel from "./AnalyticsViewModel";

const links = [{ path: "/", label: "Главная" }, { label: "Аналитика" }];

const Analytics: FC = () => {
  document.title = "Бебинокль | Аналитика";
  const {
    locationAnalytics,
    isLoading,
    analyticsData,
    isError,
    getAnalyticsData,
  } = AnalyticsViewModel;
  const { locations, getLocations } = LocationsService;

  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [filter, setFilter] = useState({ sort: "loyalty", query: "" });

  const sortedAndSearchedCharts = useFilter(
    locationAnalytics,
    filter.sort,
    filter.query
  );

  useEffect(() => {
    if (!locationAnalytics.length) {
      getAnalyticsData();
    }
    if (!locations.length) {
      // getLocations();
    }
  }, []);

  const { currentPage, totalPages, handlePageChange } = usePagination(
    sortedAndSearchedCharts?.length,
    itemsPerPage
  );

  const options = [
    { value: "name", name: "По названию" },
    { value: "loyalty", name: "По лояльности" },
  ];

  // Определяем значение xl в зависимости от размера экрана
  const isLargeScreen = useMediaQuery({ maxWidth: 1440 });
  const xlValue = isLargeScreen ? 4 : 3;

  return (
    <React.Fragment>
      <div className="page-content d-flex flex-column mb-4">
        <Container fluid={true} className="flex-grow-1 d-flex flex-column">
          <Breadcrumbs links={links} />
          <Row>
            {isLoading ? (
              [...Array(8)].map((_, index) => (
                <Col key={index} xl={xlValue} lg={6} md={6}>
                  <MyLoderChart />
                </Col>
              ))
            ) : isError ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных аналитики</Alert>
              </Col>
            ) : (
              <div
                className="flex-grow-1 d-flex flex-column"
                style={{ minHeight: "85vh" }}
              >
                <MyFilter
                  filter={filter}
                  setFilter={setFilter}
                  options={options}
                  pageValue={itemsPerPage}
                  onChangePage={(value) => setItemsPerPage(value)}
                />

                {sortedAndSearchedCharts.length === 0 ? (
                  <h3>Ничего не найдено!</h3>
                ) : (
                  <Row>
                    {sortedAndSearchedCharts
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((data: any, index: number) => {
                        return (
                          <Col xl={xlValue} lg={6} md={6} key={data.id}>
                            <RadialChart
                              dataChart={data}
                              analyticsData={analyticsData}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                )}

                <div className="mt-auto">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default observer(Analytics);
