import { observer } from "mobx-react-lite";
import React, { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import UserWizardViewModel from "../../UserWizardViewModel";
import DevicesCard from "./DevicesCard";
import DocumentsCard from "./DocumentsCard";
import FollowersCard from "./FollowersCard";
import TariffAndBalanceCard from "./TariffAndBalanceCard";
import UserCard from "./UserCard";
import UserLocationsCard from "./UserLocationsCard";

interface ProfileTabProps {
  tabName: string;
  vm: UserWizardViewModel;
  isDefault?: boolean;
}

const ProfileTab: FC<ProfileTabProps> = ({ tabName, vm, isDefault }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isVisible = useMemo(() => {
    const activeTab = searchParams.get("tab");

    if (!activeTab && isDefault) return true;
    return tabName === activeTab;
  }, [isDefault, searchParams, tabName]);

  if (!isVisible) return null;
  return (
    <Container fluid={true}>
      <Row className="mt-4">
        <Col xl={6}>
          <UserCard user={vm.currentUser} />
          <TariffAndBalanceCard vm={vm} />
          <DevicesCard />
        </Col>
        <Col xl={6}>
          <UserLocationsCard
            deleteLocation={vm.deleteLocation}
            locations={vm.locations}
          />
          <FollowersCard />
          <DocumentsCard />
        </Col>
      </Row>
    </Container>
  );
};
export default observer(ProfileTab);
