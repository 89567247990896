import { makeAutoObservable, reaction } from "mobx";
import { __MOCK_TABLE_DATA, __TablesMainColumns } from "./parts/constants";
import { TableData } from "./parts/types";

class PaymentRateViewModel {
  constructor() {
    reaction(
      () => this.tableData,
      () => {
        this.sortData();
      }
    );

    makeAutoObservable(this);
  }
  tableData: TableData = __MOCK_TABLE_DATA;
  editableUserId: string = "";
  activeLocationId: number = 0;
  activeCorpusId: number = 0;
  activeGroupId: number = 0;
  activeUserId: number = 0;
  activeSubLocationId: number = 0;
  sortedTableData: TableData = __MOCK_TABLE_DATA;
  comment?: string = undefined;
  sortFilter = {
    columnLabel: __TablesMainColumns[0].label,
    isAsc: false,
  };

  sortData() {
    const { columnLabel, isAsc } = this.sortFilter;
    if (columnLabel) {
      const sorted = [...this.tableData.items].sort((a, b) => {
        const foundLabelB = b.data.find((item) => item.label === columnLabel);
        const foundLabelA = a.data.find((item) => item.label === columnLabel);
        if (!foundLabelB || !foundLabelA) {
          return 0;
        }
        if (!isAsc) {
          if ((foundLabelB.value || 0) > (foundLabelA.value || 0)) {
            return 1;
          }
          if ((foundLabelB.value || 0) < (foundLabelA.value || 0)) {
            return -1;
          }
          return 0;
        } else {
          if ((foundLabelA.value || 0) > (foundLabelB.value || 0)) {
            return 1;
          }
          if ((foundLabelA.value || 0) < (foundLabelB.value || 0)) {
            return -1;
          }
          return 0;
        }
      });
      this.sortedTableData = { items: sorted };
    }
    this.sortedTableData = this.tableData;
  }
  get sortedData() {
    const { columnLabel, isAsc } = this.sortFilter;
    if (columnLabel) {
      const sorted = [...this.tableData.items].sort((a, b) => {
        const foundLabelB = b.data.find((item) => item.label === columnLabel);
        const foundLabelA = a.data.find((item) => item.label === columnLabel);
        if (!foundLabelB || !foundLabelA) {
          return 0;
        }
        if (!isAsc) {
          if ((foundLabelB.value || 0) > (foundLabelA.value || 0)) {
            return 1;
          }
          if ((foundLabelB.value || 0) < (foundLabelA.value || 0)) {
            return -1;
          }
          return 0;
        } else {
          if ((foundLabelA.value || 0) > (foundLabelB.value || 0)) {
            return 1;
          }
          if ((foundLabelA.value || 0) < (foundLabelB.value || 0)) {
            return -1;
          }
          return 0;
        }
      });
      return { items: sorted };
    }
    return this.tableData;
  }

  findUser() {
    const foundUser = this.tableData.items
      .find((i) => +i.id === this.activeLocationId)
      ?.items.find((i) => +i.id === this.activeSubLocationId)
      ?.items.find((i) => +i.id === this.activeCorpusId)
      ?.items.find((i) => +i.id === this.activeGroupId)
      ?.items.find((i) => i.id === this.editableUserId);
    return foundUser;
  }

  setComment = (comment: string) => {
    this.comment = comment;
  };

  onSaveComment = () => {
    this.comment = "";
  };

  setSortFilter = (data: { columnLabel: string; isAsc: boolean }) => {
    this.sortFilter = data;
  };

  setEditableUserId = (id: string) => {
    this.editableUserId = id;
    const parseData = id.split("-");
    this.activeLocationId = +parseData[0];
    this.activeSubLocationId = +parseData[1];
    this.activeCorpusId = +parseData[2];
    this.activeGroupId = +parseData[3];
    this.activeUserId = +parseData[4];
    const foundUser = this.findUser();
    if (foundUser) {
      this.comment = `${foundUser.data[10].value}` || "";
    }
  };

  saveComment = () => {
    const foundUser = this.findUser();
    if (foundUser) {
      foundUser.data[10].value = this.comment;
      this.setComment("");
    }
  };
}

export default new PaymentRateViewModel();
