import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import Course from "src/models/analitics/Course";
import LocationAnalytics from "src/models/analitics/LocationAnalytics";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import api from "src/services/ApiService";

let analyticsCache: LocationAnalytics[] = [];
let courseCache: Course[] = [];

class AnalyticsViewModel {
  constructor() {
    makeAutoObservable(this);
  }

  locationAnalytics: LocationAnalytics[] = analyticsCache;
  courseAnalytics: Course[] = courseCache;
  isLoading = false;
  isError = false;
  note = "";

  topicId = 0;
  activeUserId = 0;

  setTopicId = (topicId: number) => {
    this.topicId = topicId;
  };

  setActiveUserId = (activeUserId: number) => {
    this.activeUserId = activeUserId;
  };

  get analyticsData() {
    return {
      courses: this.courseAnalytics,
      locations: this.locationAnalytics,
    };
  }

  get parentBrakeRuleFilter() {
    return new FilterModel([
      new OptionModel("Да", "true", false),
      new OptionModel("Нет", "false", true),
    ]);
  }

  getAnalyticsData = async () => {
    this.isLoading = true;
    this.isError = false;
    try {
      const response: AxiosResponse<{
        courses: Course[];
        locations: LocationAnalytics[];
      }> = await api.get("/learning-courses/analytics");

      runInAction(() => {
        console.log(response);

        this.locationAnalytics = response.data.locations;
        this.courseAnalytics = response.data.courses;
        analyticsCache = response.data.locations;
        courseCache = response.data.courses;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isError = true;
        this.isLoading = false;
      });
    }
  };

  setCompleted = async (
    courseId: number,
    personId: number,
    groupId: number,
    locationId: number
  ) => {
    try {
      const response = await api.post(
        `/learning-courses/set-completed?user_id=${personId}&course_id=${courseId}`
      );

      runInAction(() => {
        const foundCourse = this.locationAnalytics
          .find((location) => location.id === locationId)
          ?.groups.find((group) => group.id === groupId)
          ?.users.find((user) => user.id === personId)
          ?.courses_result.find((course) => course.course_id === courseId);

        if (foundCourse) {
          foundCourse.value = 100;
        } else {
          this.getAnalyticsData();
        }
      });
    } catch (error) {}
  };

  sendNote = async () => {
    try {
      await api.post(
        `/learning-courses/make-note?user_id=${this.activeUserId}&topic_id=${this.topicId}&is_violation=${this.parentBrakeRuleFilter.selectedOptions[0].value}&message=${this.note}`
      );
    } catch (error) {}
  };

  setNote = (note: string) => {
    this.note = note;
  };
}

export default new AnalyticsViewModel();
