import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useMemo, useState } from "react";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import styles from "./AppSelector.module.scss";

export interface AppSelectOption {
  label: string;
  value: string | number;
}

interface AppSelectorProps {
  label?: string;
  onChange?: (option: OptionModel) => void;
  filter: FilterModel;
  onCancel?: () => void;
  multiple?: boolean;
  placeholder?: string;
  value?: string | number;
  options?: AppSelectOption[];
  isDisabled?: boolean;
  isLoading?: boolean;
  extraStyles?: {
    wrapper?: string;
  };
  backgroundColor?: string;
  isRequired?: boolean;
}

const AppSelector: FC<AppSelectorProps> = ({
  filter,
  label,
  onChange,
  onCancel,
  multiple,
  placeholder,
  value,
  options,
  isDisabled,
  isLoading,
  extraStyles,
  backgroundColor = "#fff",
  isRequired,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLabel = useMemo(() => {
    if (filter.selectedOptions.length > 0) {
      if (filter.selectedOptions.length > 1) {
        return `${filter.selectedOptions.length} выбрано`;
      }
      return filter.selectedOptions[0].label;
    }
    return filter.label || placeholder;
  }, [filter.label, filter.selectedOptions, placeholder]);
  const handleClick = useCallback(
    (option: OptionModel) => {
      filter.onSelect(option);
      if (!multiple && !filter.isMultiple) {
        setIsOpen(false);
      }
    },
    [filter, multiple]
  );
  return (
    <div className={"w-100"}>
      {!!label && <h6>{label}</h6>}
      <div className={classnames("d-flex", styles.btn, extraStyles?.wrapper)}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (isDisabled) {
              return;
            }
            setIsOpen((prev) => !prev);
          }}
          style={{ backgroundColor }}
          className={classnames(
            styles.select,
            "justify-content-between d-flex align-items-center",
            { [styles.disabled]: isDisabled }
          )}
        >
          <div className={styles.currentWrapper}>
            {isOpen && (
              <>
                <div className={styles.wrapper} />
                <div className={classnames(styles.modalMenu)}>
                  <div>
                    {filter.options.map((item, index) => (
                      <div
                        style={{ backgroundColor }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(item);
                        }}
                        className={classnames([
                          styles.dropdownItem,
                          { [styles.isSelected]: item.isSelected },
                        ])}
                        key={index}
                      >
                        <span>{item.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {!!currentLabel && (
              <div className={styles.currentLabel}>
                {isLoading ? "Загрузка..." : currentLabel}
              </div>
            )}
          </div>
          <i
            className={`mdi mdi-chevron-down
        ${isOpen && "mdi-chevron-up"}`}
          ></i>
        </div>
        {filter.selectedOptions.length > 0 && !isRequired && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              !isDisabled && filter.deselectAll();
            }}
            className={classnames(
              "d-flex align-items-center justify-content-center",
              styles.close
            )}
          >
            <i className="mdi mdi-close"></i>
          </div>
        )}
      </div>
    </div>
  );
};
export default observer(AppSelector);
