import { observer } from "mobx-react-lite";
import React, { FC, useContext, useMemo } from "react";
import { Col, Row } from "reactstrap";
import ModalMenu from "src/components/Common/ModalMenu/ModalMenu";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import { PaymentContext } from "../Context";
import PaymentRateViewModel from "../PaymentRateViewModel";
import TableBody from "./TableBody";
import TablesHeader from "./TablesHeader";
import { __TablesMainColumns } from "./constants";
import { ColumnOption } from "./types";

interface MainTableProps {}

const MainTable: FC<MainTableProps> = ({}) => {
  const paymentContext = useContext(PaymentContext);

  const { sortFilter, setSortFilter, sortedData, tableData, sortedTableData } =
    PaymentRateViewModel;

  const currentMenu = useMemo(() => {
    return new FilterModel(
      __TablesMainColumns.map(
        (item) =>
          new OptionModel(
            item.label,
            item.label,
            true,
            item.isRequired,
            item.isSortable
          )
      ),
      true
    );
  }, []);

  const handleFilter = (e: OptionModel) => {
    if (e.label === sortFilter.columnLabel) {
      setSortFilter({
        ...sortFilter,
        isAsc: !sortFilter.isAsc,
      });
    } else {
      setSortFilter({ columnLabel: e.label, isAsc: false });
    }
  };

  return (
    <div className="">
      <Row>
        <Col sm={12} md={6}>
          <div className="dt-buttons btn-group flex-wrap mb-2">
            <button
              className="btn btn-secondary buttons-copy buttons-html5"
              tabIndex={0}
              aria-controls="datatable-buttons"
              type="button"
            >
              <span>Copy</span>
            </button>{" "}
            <button
              className="btn btn-secondary buttons-excel buttons-html5"
              tabIndex={0}
              aria-controls="datatable-buttons"
              type="button"
            >
              <span>Excel</span>
            </button>{" "}
            <button
              className="btn btn-secondary buttons-pdf buttons-html5"
              tabIndex={0}
              aria-controls="datatable-buttons"
              type="button"
            >
              <span>PDF</span>
            </button>{" "}
            <div className="btn-group">
              <button
                className="btn btn-secondary buttons-collection dropdown-toggle buttons-colvis"
                tabIndex={0}
                aria-controls="datatable-buttons"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                onClick={(e) => {
                  e.stopPropagation();
                  paymentContext[1](!paymentContext[0]);
                }}
              >
                {paymentContext[0] && <ModalMenu filter={currentMenu} />}
                <span>Видимые колонки</span>
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={"12"}>
          <table
            id={"datatable-buttons"}
            tabIndex={0}
            className="table  table-bordered dt-responsive nowrap dataTable no-footer dtr-inline"
          >
            <TablesHeader
              colLabel={sortFilter.columnLabel}
              isAsc={sortFilter.isAsc}
              onSort={handleFilter}
              options={currentMenu.selectedOptions}
            />
            <TableBody
              options={currentMenu.selectedOptions as unknown as ColumnOption[]}
              tableData={sortedData}
            />
          </table>
        </Col>
      </Row>
    </div>
  );
};
export default observer(MainTable);
