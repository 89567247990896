// actions.js
import {
  GET_CAMERAS,
  GET_CAMERAS_SUCCESS,
  GET_CAMERAS_FAIL,
  SET_CAMERAS_LOADING, // Импортируем новый тип действия
  LOGOUT_USER_CAMERAS,
  FILTER_CAMERAS,
  SET_FILTER_CAMERAS_LOADING,
  GET_FILTER_CAMERAS_SUCCESS,
  GET_FILTER_CAMERAS_FAIL,
} from "./actionTypes";

export const fetchCameras = () => ({
  type: GET_CAMERAS,
});

export const filterCameras = (id, additionalParams) => ({
  type: FILTER_CAMERAS,
  payload: { id, additionalParams },
});

export const setFilterCamerasLoading = (loadingFilter) => ({
  type: SET_FILTER_CAMERAS_LOADING,
  payload: loadingFilter,
});

export const fetchFilterCamerasSuccess = (analyticsData) => ({
  type: GET_FILTER_CAMERAS_SUCCESS,
  payload: analyticsData,
});

export const fetchCamerasSuccess = (analyticsData) => ({
  type: GET_CAMERAS_SUCCESS,
  payload: analyticsData,
});

export const fetchFilterCamerasFail = (error) => ({
  type: GET_FILTER_CAMERAS_FAIL,
  payload: error,
});

export const fetchCamerasFail = (error) => ({
  type: GET_CAMERAS_FAIL,
  payload: error,
});

export const setCamerasLoading = (loading) => ({
  // Новое действие для установки состояния загрузки
  type: SET_CAMERAS_LOADING,
  payload: loading,
});

export const logoutUserCameras = () => ({
  type: LOGOUT_USER_CAMERAS,
});
