import { observer } from "mobx-react-lite";
import React, { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Page } from "src/components/complexes/Page";
import UsersListViewModel from "./UsersListViewModel";
import UsersFilter from "./parts/UsersFilter";
import UsersList from "./parts/UsersList";

const __LINKS__ = [
  { path: "/", label: "Главная" },
  { path: "/users", label: "Пользователи" },
  { path: `/users/usersList`, label: "Список пользователей" },
];

interface UsersListPageProps {}

const UsersListPage: FC<UsersListPageProps> = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const vm = useMemo(() => {
    const page = searchParams.get("page");
    setSearchParams({ page: page || "1" });
    return new UsersListViewModel(page ? +page : 1);
  }, []);
  return (
    <Page links={__LINKS__}>
      <UsersFilter vm={vm} />
      <UsersList vm={vm} />
    </Page>
  );
};
export default observer(UsersListPage);
