import React, { FC, memo } from "react";
import { Col } from "reactstrap";

interface ValueItemProps {
  label: string;
  icon: string;
  value: number | string;
  valueLabel: string;
  color: string;
}

const ValueItem: FC<ValueItemProps> = ({
  label,
  icon,
  value,
  valueLabel,
  color,
}) => {
  return (
    <Col xl={4}>
      <div className="social-source text-center mt-3">
        <div className="avatar-xs mx-auto mb-3">
          <span
            style={{ backgroundColor: color }}
            className="avatar-title rounded-circle font-size-18"
          >
            <i className={`${icon}`}></i>
          </span>
        </div>
        <h5 style={{ height: "35px" }} className="font-size-15">
          {label}
        </h5>
        <p className="text-muted mb-0">
          {value} {valueLabel}
        </p>
      </div>
    </Col>
  );
};
export default memo(ValueItem);
