import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { AppCheckbox } from "src/components/Common/AppCheckbox";
import { EditButton } from "src/components/Common/EditButton";
import { AppModal } from "src/components/Common/Modal";
import styles from "../../UserWizard.module.scss";
import UserWizardViewModel from "../../UserWizardViewModel";

interface TariffAndBalanceCardProps {
  vm: UserWizardViewModel;
}

const TariffAndBalanceCard: FC<TariffAndBalanceCardProps> = ({ vm }) => {
  const { currentUser } = vm;
  const modalQuestion = useRef<string>("");
  const [viewModal, setViewModal] = React.useState(false);

  const toggleEnableAutoPay = useCallback(() => {
    setViewModal(true);
    modalQuestion.current = currentUser?.isAutoPayment
      ? " Вы уверены, что хотите отключить автоплатеж?"
      : "Вы уверены, что хотите включить автоплатеж?";
  }, [currentUser?.isAutoPayment]);

  const onSubmitModal = useCallback(() => {
    setViewModal(false);
    if (currentUser) {
      runInAction(() => {
        currentUser.isAutoPayment = !currentUser.isAutoPayment;
      });
    }
  }, [currentUser]);

  if (!currentUser) return null;
  return (
    <Card>
      <CardTitle className="font-weight-bold p-2">
        Управление тарифом и балансом
      </CardTitle>
      <CardBody>
        <Row>
          <Col xl={7}>
            <Row>
              <Col xl={8}>
                <b>Баланс</b>
              </Col>
              <Col className="d-flex justify-content-center" xl={2}>
                <div className="">{currentUser.balance}</div>
              </Col>
              <Col className="d-flex justify-content-center" xl={2}>
                <EditButton onClick={() => {}} />
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <b>Списания в день</b>
              </Col>

              <Col className="d-flex justify-content-center" xl={2}>
                <div className="">{currentUser.balance}</div>
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <b>Остаток дней</b>
              </Col>
              <Col className="d-flex justify-content-center" xl={2}>
                <span className="">{currentUser.restOfDays}</span>
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <b>Автоплатеж</b>
              </Col>
              <Col xl={2}> </Col>
              <Col className="d-flex justify-content-center" xl={2}>
                <AppCheckbox
                  isChecked={currentUser.isAutoPayment}
                  onClick={toggleEnableAutoPay}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <b>Триал</b>
              </Col>
              <Col xl={2}> </Col>
              <Col className="d-flex justify-content-center" xl={2}>
                <EditButton onClick={() => {}} />
              </Col>
            </Row>
          </Col>
          <Col xl={5}>
            <Row>
              <Col xl={12}>
                <div className={styles.tariff}>{currentUser.tariff}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
      <AppModal
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        onSubmit={onSubmitModal}
        positiveButtonLabel="Да"
        negativeButtonLabel="Нет"
      >
        {modalQuestion.current}
      </AppModal>
    </Card>
  );
};
export default observer(TariffAndBalanceCard);
