import { observer } from "mobx-react-lite";
import React, { FC, useLayoutEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import { UserRoles } from "src/models/profile/UserRoles";
import ProfileService from "src/services/ProfileService";
import DashboardViewModel from "./DashboardViewModel";
import HeaderPercentMeterItem from "./parts/HeaderPercentMeterItem/HeaderPercentMeterItem";
import { MainChart } from "./parts/MainChart";
import { UsersActivityChart } from "./parts/UsersActivityChart";

interface DashboardProps {}

//brandcrumb
const links = [
  { path: "/", label: "Главная" },
  { path: "/dashboard", label: "Dashboard" },
];
const Dashboard: FC<DashboardProps> = ({}) => {
  const { profile, isLoading } = ProfileService;
  const { dashboardData, history, activeUsersData, getDashboardData } = useMemo(
    () => new DashboardViewModel(),
    []
  );
  const router = useNavigate();

  useLayoutEffect(() => {
    if (!isLoading && (!profile || profile.role !== UserRoles.SUPERADMIN)) {
      router("/analytics");
    } else {
      getDashboardData();
    }
  }, [getDashboardData, isLoading, profile, router]);
  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xl={12}>
              <Breadcrumbs links={links} />
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={6} md={12}>
              <HeaderPercentMeterItem
                value={dashboardData?.active_locations.percent || 0}
                label={"Активные локации"}
                differentValue={dashboardData?.active_locations.diff || 0}
                absoluteValue={dashboardData?.active_locations.total || 0}
              />
            </Col>
            <Col xl={3} lg={6} md={12}>
              <HeaderPercentMeterItem
                value={dashboardData?.infiltration.percent || 0}
                label={"Проникновение"}
                differentValue={dashboardData?.infiltration.diff || 0}
                absoluteValue={dashboardData?.infiltration.total || 0}
              />
            </Col>
            <Col xl={3} lg={6} md={12}>
              <HeaderPercentMeterItem
                value={dashboardData?.payment_rate.percent || 0}
                label={"Норма платежа"}
                differentValue={dashboardData?.payment_rate.diff || 0}
                absoluteValue={dashboardData?.payment_rate.total || 0}
              />
            </Col>
            <Col xl={3} lg={6} md={12}>
              <HeaderPercentMeterItem
                value={dashboardData?.payment_users.percent || 0}
                label={"Процент платящих"}
                differentValue={dashboardData?.payment_users.diff || 0}
                absoluteValue={dashboardData?.payment_users.total || 0}
              />
            </Col>
          </Row>
          <Row>
            <MainChart paymentHistory={history} />
            <UsersActivityChart activeUsers={activeUsersData} />
          </Row>
          <Row>
            <Col xl={12}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default observer(Dashboard);
