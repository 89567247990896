import classNames from 'classnames';
import React, { useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import styles from './Pagination.module.scss';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePressPage = useCallback(
    (page) => {
      setSearchParams({ page });
      onPageChange(page);
    },
    [onPageChange, setSearchParams]
  );
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${currentPage === number ? "active" : ""}`}
      >
        <span className="page-link" onClick={() => handlePressPage(number)}>
          {number}
        </span>
      </li>
    ));
  };

  return (
    <Row>
      <Col lg={12}>
        <Card className="mb-0">
          <CardBody>
            <div className={classNames("d-flex align-items-center", styles.wrapper)}>

          <span className={`page-item ${currentPage === 1 && "disabled"}`}>
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => handlePressPage(currentPage - 1)}
                  >
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </span>
            <div
              className={classNames("d-inline-block", styles.pagination)}
            >
              <ul className="pagination pagination-rounded mb-0">
                
                {renderPageNumbers()}

              </ul>
            </div>
            <span
                  className={`page-item ${
                    currentPage === totalPages && "disabled"
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => handlePressPage(currentPage + 1)}
                  >
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </span>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Pagination;
