// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getCameras,
  getFilteredCamerasURL,
} from "../../helpers/fakebackend_helper";
import { FILTER_CAMERAS, GET_CAMERAS } from "./actionTypes";
import {
  fetchCamerasFail,
  fetchCamerasSuccess,
  fetchFilterCamerasFail,
  fetchFilterCamerasSuccess,
  setCamerasLoading,
  setFilterCamerasLoading,
} from "./actions";

function* fetchCamerasSaga() {
  try {
    yield put(setCamerasLoading(true)); // Устанавливаем loading в true перед запросом данных
    const response = yield call(getCameras); // Вызываем функцию для получения данных аналитики
    yield put(fetchCamerasSuccess(response)); // Отправляем успешный результат в хранилище
    yield put(setCamerasLoading(false)); // Устанавливаем loading в false после получения данных
  } catch (error) {
    console.log(error);
    yield put(fetchCamerasFail(error)); // Отправляем ошибку в хранилище в случае неудачи
    yield put(setCamerasLoading(false)); // Устанавливаем loading в false в случае ошибки
  }
}

function* filtersCamerasSaga(action) {
  try {
    yield put(setFilterCamerasLoading(true));

    // Получение параметров из payload экшена
    const { id, additionalParams } = action.payload;

    const response = yield call(getFilteredCamerasURL, id, additionalParams);
    yield put(fetchFilterCamerasSuccess(response));
    yield put(setFilterCamerasLoading(false));
  } catch (error) {
    yield put(fetchFilterCamerasFail(error));
    yield put(setFilterCamerasLoading(false));
  }
}

function* watchFetchCameras() {
  yield takeEvery(GET_CAMERAS, fetchCamerasSaga);
  yield takeEvery(FILTER_CAMERAS, filtersCamerasSaga);
}

export default watchFetchCameras;
