import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { DeleteButton } from "src/components/Common/DeleteButton";
import { EditButton } from "src/components/Common/EditButton";
import { AppModal } from "src/components/Common/Modal";
import styles from "../../UserWizard.module.scss";
interface Device {
  id: string;
  platform: string;
  isMain: boolean;
}
interface DevicesCardProps {}

const DevicesCard: FC<DevicesCardProps> = ({}) => {
  const [devices, setDevices] = React.useState<Device[]>([
    {
      id: "1adhasgfagjsf765678afs8",
      platform: "Android",
      isMain: true,
    },
  ]);
  const activeDevice = useRef<Device>();
  const [viewModal, setViewModal] = React.useState(false);

  const handleDeleteDevice = useCallback((device: Device) => {
    activeDevice.current = device;
    setViewModal(true);
  }, []);

  const onSubmitModal = useCallback(() => {
    setDevices((prev) =>
      prev.filter((device) => device.id !== activeDevice.current!.id)
    );
    setViewModal(false);
  }, []);
  return (
    <Card>
      <CardTitle className="font-weight-bold p-2">Устройства</CardTitle>
      <CardBody>
        {devices.length === 0 && <div>У пользователя нет устройств</div>}
        {devices.map((device) => (
          <Row key={device.id}>
            <Col xl={7}>
              <Row>
                <Col xl={4}>
                  <b>ID</b>
                </Col>
                <Col xl={7}>{device.id}</Col>
                <Col xl={1}>
                  <DeleteButton
                    onClick={() => {
                      handleDeleteDevice(device);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <b>Платформа</b>
                </Col>
                <Col xl={8}>{device.platform}</Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <b>Пароль</b>
                </Col>
                <Col xl={7}></Col>
                <Col xl={1}>
                  <EditButton onClick={() => {}} />
                </Col>
              </Row>
            </Col>
            <Col xl={5}>
              {/* <Row> */}
              {/* <Col xl={12}> */}
              <div className="d-flex flex-column justify-content-between h-100">
                {device.isMain && (
                  <div className={styles.mainDevice}>
                    <span>Основное устройство</span>
                  </div>
                )}
                <button
                  className={classNames("btn btn-primary ", styles.smsDevice)}
                >
                  Смс пароль клиенту
                </button>
              </div>
            </Col>
          </Row>
        ))}
      </CardBody>
      <AppModal
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        onSubmit={onSubmitModal}
        positiveButtonLabel="Да"
        negativeButtonLabel="Нет"
      >
        {'Вы уверены, что хотите удалить устройство "' +
          activeDevice.current?.id +
          '"?'}
      </AppModal>
    </Card>
  );
};
export default observer(DevicesCard);
