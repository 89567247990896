import { makeAutoObservable } from "mobx";

class AppService {
  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  isAuth = false;
  activeMenuName = "";
  setActiveMenuName = (name: string) => {
    this.activeMenuName = name;
  };

  setIsAuth = (isAuth: boolean) => {
    this.isAuth = isAuth;
  };
}

export default new AppService();
