import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { OptionModel } from "src/models/common/FilterModel";

interface RadioItemProps {
  option: OptionModel;
  onClick: (option: OptionModel) => void;
}

const RadioItem: FC<RadioItemProps> = ({ option, onClick }) => {
  return (
    <>
      <label
        className={`btn ${
          option.isSelected ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <input
          type="radio"
          className={"btn-check"}
          name="btnradio"
          id={`${option.value}`}
          checked={option.isSelected}
          onChange={() => onClick(option)}
        />
        {option.label}
      </label>
    </>
  );
};
export default observer(RadioItem);
