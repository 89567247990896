import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";
//i18n
import { observer } from "mobx-react-lite";
import { withTranslation } from "react-i18next";
import Location from "src/models/location/Location";
import AppService from "src/services/AppService";
import LocationsService from "src/services/LocationsService";
import ProfileService from "src/services/ProfileService";
import MenuNavItem, { IMenuItem } from "./MenuNavItem";
import { MenuNames, __SidebarMenu__ } from "./constants";

const Sidebar = (props: any) => {
  const { profile } = ProfileService;
  const { locations, isLoading, getLocations } = LocationsService;
  const { activeMenuName } = AppService;

  useEffect(() => {
    if (
      activeMenuName === MenuNames.CAMERAS &&
      !locations.length &&
      !isLoading
    ) {
      getLocations();
    }
  }, [activeMenuName, getLocations, isLoading, locations.length]);

  const actualSideMenu = useMemo(() => {
    const prepareMenu = __SidebarMenu__.map((item) => {
      if (item.subItem?.length) {
        const availableSubItems: IMenuItem[] = [];
        item.subItem.forEach((subItem) => {
          if (
            (subItem.availableRoles &&
              profile &&
              subItem.availableRoles.includes(profile.role)) ||
            subItem.availableRoles === undefined
          ) {
            availableSubItems.push(subItem);
          }
        });
        if (availableSubItems.length) {
          return {
            ...item,
            subItem: availableSubItems,
          };
        } else {
          return null;
        }
      }
      if (item.id === "cameras") {
        return {
          ...item,
          subItem: isLoading
            ? [{ sublabel: "Идет загрузка ..." }]
            : locations &&
              locations?.length &&
              locations.map((corpus: Location) => ({
                id: corpus.id,
                sublabel: corpus.name,
                link: `/cameras/${corpus.id}`,
              })),
        };
      }

      return item;
    });

    return prepareMenu.filter((i) => i !== null);
  }, [isLoading, locations, profile]);

  // !TODO - нужен рефакторинг
  const ref = useRef<any>(null);
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // !TODO - нужен рефакторинг
  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }
        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };
  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul && ul.getElementsByTagName("span");
    removeActivation(items);
    // if (items) {
    //   for (let i = 0; i < items.length; ++i) {
    //     if (fullPath === items[i].pathname) {
    //       matchingMenuItem = items[i];
    //       break;
    //     }
    //   }
    // }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);
  useEffect(() => {
    ref.current && ref.current.recalculate();
  }, []);
  useEffect(() => {
    new MetisMenu("#side-menu-item", {
      triggerElement: "span",
    });
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    activeMenu();
  }, [activeMenu]);
  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {(actualSideMenu || []).map((item, key) => (
                <MenuNavItem
                  // @ts-ignore
                  item={item}
                  key={key}
                />
              ))}
            </ul>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};
Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(observer(Sidebar)));
