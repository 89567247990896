import React, { FC } from "react";

interface OnLineButtonProps {
  isOnline: boolean;
  onClick: () => void;
}

const OnLineButton: FC<OnLineButtonProps> = ({ onClick, isOnline }) => {
  return (
    <button
      onClick={onClick}
      style={{ marginLeft: 30 }}
      className={`btn ${isOnline ? "btn-primary" : "btn-secondary"}`}
    >
      {isOnline ? "Прямой эфир" : "Вернуться в эфир"}
    </button>
  );
};
export default OnLineButton;
