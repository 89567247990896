import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import TableRow from "./TableRow";
import { ColumnOption, TableData } from "./types";

interface TableBodyProps {
  tableData: TableData;
  options: ColumnOption[];
}

const TableBody: FC<TableBodyProps> = ({ tableData, options }) => {
  return (
    <tbody>
      {tableData.items.map((item, index) => {
        return (
          <TableRow
            isVisible
            key={item.locationName}
            hasChildren={!!item.items.length}
            typeView={item.className}
            data={item}
            firstLabel={item.locationName}
            options={options}
          />
        );
      })}
    </tbody>
  );
};
export default observer(TableBody);
