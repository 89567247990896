import { makeAutoObservable, runInAction } from "mobx";
import UserAPI from "src/data/UserAPI";
import ICommonData from "src/models/common/CommonData";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import GetUsersDTO, { UsersResponse } from "src/models/users/GetUsersDTO";
import ISearchForm, { IOrganizationForm } from "src/models/users/ISearchForm";
import UserListItem from "src/models/users/UserListItem";
import { __UsersTablesColumns } from "./constants";

class UsersListViewModel {
  constructor(page: number = 1) {
    this.page = page;
    this.fetchData();
    this.getUsers();
    makeAutoObservable(this);
  }
  cities: ICommonData[] = [];
  organizations: IOrganizationForm[] = [];
  statuses: ICommonData[] = [];
  roles: ICommonData[] = [];
  tariffs: ICommonData[] = [];
  blocks: ICommonData[] = [];
  trialLefts: ICommonData[] = [];
  daysLefts: ICommonData[] = [];
  startTimeFilter?: string = undefined;
  endTimeFilter?: string = undefined;
  dateFilter?: string = undefined;
  endDateFilter?: string = undefined;

  users: UserListItem[] = [];
  offset: number = 0;
  limit: number = 30;
  page: number = 1;
  totalCount: number = 0;
  order_by: "ASC" | "DESC" = "ASC";
  sort_by: keyof UserListItem = "id";
  search?: string = undefined;

  sortFilter = {
    columnValue: __UsersTablesColumns[0].value,
    isAsc: false,
  };

  isLoading = false;
  selectedUsersIds: number[] = [];
  isSelectedAll: boolean = false;

  isChangingSort = false;

  get totalPages() {
    return Math.ceil(this.totalCount / this.limit);
  }
  get citiesFilter() {
    return new FilterModel(
      this.cities.map((item) => new OptionModel(item.name, item.id, false))
    );
  }
  get locationFilter() {
    return new FilterModel(
      this.organizations.map(
        (item) => new OptionModel(item.name, item.id, false)
      )
    );
  }
  get groupClassFilter() {
    if (!this.locationFilter.selectedOptions[0]) return new FilterModel([]);
    const activeLocation = this.organizations.find(
      (item) => item.id === this.locationFilter.selectedOptions[0].value
    );

    if (activeLocation) {
      return new FilterModel(
        activeLocation.groups.map(
          (item) => new OptionModel(item.name, item.id, false)
        )
      );
    }
    return new FilterModel([]);
  }
  get roleFilter() {
    return new FilterModel(
      this.roles.map((role) => new OptionModel(role.name, role.id, false))
    );
  }

  get blockFilter() {
    return new FilterModel(
      [
        new OptionModel("Да", "true", false),
        new OptionModel("Нет", "false", false),
      ],
      false,
      "Блокирован"
    );
  }
  get balancePlusFilter() {
    return new FilterModel(
      [
        new OptionModel("Да", "true", false),
        new OptionModel("Нет", "false", false),
      ],
      false,
      "Баланс +"
    );
  }
  get trialPlusFilter() {
    return new FilterModel(
      [
        new OptionModel("Да", "true", false),
        new OptionModel("Нет", "false", false),
      ],
      false,
      "Триал +"
    );
  }

  // demo
  get blockedReasonFilter() {
    return new FilterModel(
      this.blocks.map((item) => new OptionModel(item.name, item.id, false)),
      false,
      "Причина блокировки"
    );
  }

  get restOfDaysFilter() {
    return new FilterModel(
      this.daysLefts.map((item) => new OptionModel(item.name, item.id, false)),
      false,
      "Остаток дней"
    );
  }

  get restOfTrialFilter() {
    return new FilterModel(
      this.trialLefts.map((item) => new OptionModel(item.name, item.id, false)),
      false,
      "Остаток триала"
    );
  }
  get tariffFilter() {
    return new FilterModel(
      this.tariffs.map((item) => new OptionModel(item.name, item.id, false)),
      false,
      "Тариф"
    );
  }
  get statusFilter() {
    return new FilterModel(
      this.statuses.map((item) => new OptionModel(item.name, item.id, false)),
      false,
      "Статус"
    );
  }

  get filterParams(): GetUsersDTO {
    const transformDate = (val?: string) => {
      if (!val) return undefined;
      const d = val.split("T");
      return d[0] + " " + d[1] + ":00";
    };
    const createDate = {
      created_at_from: transformDate(this.dateFilter),
      created_at_to: transformDate(this.endDateFilter),
    };
    return {
      city_id: this.citiesFilter.selectedOptions[0]?.value as number,
      organization_id: this.locationFilter.selectedOptions[0]?.value as number,
      group_id: this.groupClassFilter.selectedOptions[0]?.value as number,
      role_id: this.roleFilter.selectedOptions[0]?.value as number,
      rate_id: this.tariffFilter.selectedOptions[0]?.value as number,
      status: this.statusFilter.selectedOptions[0]?.value as number,
      is_blocked:
        this.blockFilter.selectedOptions[0]?.value === "true"
          ? true
          : this.blockFilter.selectedOptions[0]?.value === "false"
          ? false
          : undefined,
      has_balance:
        this.balancePlusFilter.selectedOptions[0]?.value === "true"
          ? true
          : this.balancePlusFilter.selectedOptions[0]?.value === "false"
          ? false
          : undefined,
      has_trial:
        this.trialPlusFilter.selectedOptions[0]?.value === "true"
          ? true
          : this.trialPlusFilter.selectedOptions[0]?.value === "false"
          ? false
          : undefined,
      block_cause_id: this.blockedReasonFilter.selectedOptions[0]
        ?.value as number,
      days_left: this.restOfDaysFilter.selectedOptions[0]?.value as number,
      trial_left: this.restOfTrialFilter.selectedOptions[0]?.value as number,
      offset: this.limit * (this.page - 1),
      limit: this.limit,
      sort_by: this.sort_by,
      order_by: this.order_by,
      search: this.search,
      ...createDate,
    };
  }

  setStartTimeFilter = (value: string) => {
    this.startTimeFilter = value;
  };
  setEndTimeFilter = (value: string) => {
    this.endTimeFilter = value;
  };
  setDateFilter = (value: string) => {
    this.dateFilter = value;
  };
  setEndDateFilter = (value: string) => {
    this.endDateFilter = value;
  };

  fetchData = async () => {
    const response: ISearchForm = await UserAPI.getUsersSearchFormData();

    runInAction(() => {
      this.cities = response.cities;
      this.organizations = response.organizations;
      this.statuses = response.statuses;
      this.tariffs = response.rates;
      this.roles = response.roles;
      this.blocks = response.blocks;
      this.daysLefts = response.days_left;
      this.trialLefts = response.trial_left;
    });
  };

  getUsers = async () => {
    this.isLoading = this.isChangingSort ? false : true;
    const response: UsersResponse = await UserAPI.getUsers(this.filterParams);
    runInAction(() => {
      this.users = response.users;
      this.totalCount = response.count;
      this.isLoading = false;
    });
  };

  setSortFilter = async (data: {
    sort_by: keyof UserListItem;
    isAsc: boolean;
  }) => {
    this.sort_by = data.sort_by;
    this.order_by = data.isAsc ? "ASC" : "DESC";
    this.isChangingSort = true;
    await this.getUsers();
    runInAction(() => {
      this.isChangingSort = false;
    });
  };

  toggleUser = (id: number) => {
    if (this.selectedUsersIds.includes(id)) {
      this.selectedUsersIds = this.selectedUsersIds.filter((i) => i !== id);
      this.isSelectedAll = false;
    } else {
      this.selectedUsersIds = [...this.selectedUsersIds, id];
    }
  };
  toggleAllUsers = () => {
    if (this.isSelectedAll) {
      this.selectedUsersIds = [];
      this.isSelectedAll = false;
      return;
    }
    this.selectedUsersIds = this.users.map((item) => item.id);
    this.isSelectedAll = !this.isSelectedAll;
  };

  setPage = async (page: number) => {
    this.page = page;
    await this.getUsers();
  };
  applyFilter = () => {
    this.page = 1;
    this.getUsers();
    this.offset = 0;
  };
  setSearch = (value: string) => {
    this.search = value;
  };
  getExcel = async () => {
    const response: UsersResponse = await UserAPI.getUsers({
      ...this.filterParams,
      export: true,
    });
    const link = document.createElement("a");
    link.download = response.export_file;
    link.href = response.export_file;
    link.click();
    link.remove();
  };
}

export default UsersListViewModel;
