// actionTypes.js
export const GET_CAMERAS = "GET_CAMERAS";
export const GET_CAMERAS_SUCCESS = "GET_CAMERAS_SUCCESS";
export const GET_CAMERAS_FAIL = "GET_CAMERAS_FAIL";
export const SET_CAMERAS_LOADING = "SET_CAMERAS_LOADING";
export const LOGOUT_USER_CAMERAS = "LOGOUT_USER_CAMERAS";
export const FILTER_CAMERAS = "FILTER_CAMERAS";
export const SET_FILTER_CAMERAS_LOADING = "SET_FILTER_CAMERAS_LOADING";
export const GET_FILTER_CAMERAS_SUCCESS = "GET_FILTER_CAMERAS_SUCCESS";
export const GET_FILTER_CAMERAS_FAIL = "GET_FILTER_CAMERAS_FAIL";
