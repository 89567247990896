export interface IRadialChart {
  percent: number;
  total: number;
  diff: number;
}

export enum PeriodNames {
  MONTH = "month",
  YEAR = "year",
  HALF_YEAR = "half_year",
}

export interface IHistoryItem {
  label: number | string;
  new: number;
  new_diff: number;
  repeat: number;
  repeat_diff: number;
  missed: number;
  missed_diff: number;
  possible: number;
  possible_diff: number;
}

export interface IPaymentHistory {
  [PeriodNames.MONTH]: Array<IHistoryItem>;
  [PeriodNames.HALF_YEAR]: Array<IHistoryItem>;
  [PeriodNames.YEAR]: Array<IHistoryItem>;
}

export interface IActiveUsers {
  today: number;
  now: number;
  time_avg: number;
  time_max: number;
  today_max: number;
  now_max: number;
}

export default interface IDashboard {
  active_locations: IRadialChart;
  infiltration: IRadialChart;
  payment_rate: IRadialChart;
  payment_users: IRadialChart;
  payment_history: IPaymentHistory;
  active_users: IActiveUsers;
}
