import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { FilterModel } from "src/models/common/FilterModel";
import styles from "./AppRadioButton.module.scss";
import RadioItem from "./RadioItem";

interface AppRadioButtonProps {
  filter: FilterModel;
}

const AppRadioButton: FC<AppRadioButtonProps> = ({ filter }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{filter.label}</div>
      <div className="d-flex flex-wrap">
        <div className="btn-group" role="group">
          {filter.options.map((item, index) => (
            <RadioItem onClick={filter.onSelect} key={index} option={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default observer(AppRadioButton);
