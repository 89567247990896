import { makeAutoObservable } from "mobx";

export class FilterModel {
  options: OptionModel[] = [];
  isMultiple: boolean = false;
  label: string = "";
  extraData: {
    [key: string]: any;
  } = {};

  constructor(
    options: OptionModel[],
    isMultiple?: boolean,
    label?: string,
    extraData?: {
      [key: string]: any;
    }
  ) {
    this.options = options;
    this.extraData = extraData ? extraData : {};
    this.isMultiple = isMultiple ? isMultiple : false;
    this.label = label ? label : "";
    makeAutoObservable(this);
  }

  get selectedOptions() {
    return this.options.filter((option) => option.isSelected);
  }

  onSelect = (option: OptionModel) => {
    if (this.isMultiple) {
      option.onClick();
    } else {
      this.options.forEach((item) => {
        item.deselect();
      });
      option.onClick();
    }
  };

  deselectAll = () => {
    this.options.forEach((item) => {
      item.deselect();
    });
  };
}

export class OptionModel {
  label: string = "";
  value: string | number = "";
  isAlwaysSelected: boolean = false;
  isSortable: boolean = false;
  extraData: {
    [key: string]: any;
  } = {};
  isMultiple?: boolean = true;

  private isSelectedNow: boolean = false;
  constructor(
    label: string,
    value: string | number,
    isSelected?: boolean,
    isAlwaysSelected?: boolean,
    isSortable?: boolean,
    extraData?: {
      [key: string]: any;
    },
    isMultiple?: boolean
  ) {
    this.label = label;
    this.value = value;
    this.isSelectedNow = isSelected ? isSelected : false;
    this.isAlwaysSelected = isAlwaysSelected ? isAlwaysSelected : false;
    this.isSortable = isSortable ? isSortable : false;
    this.extraData = extraData ? extraData : {};
    this.isMultiple = isMultiple ? isMultiple : false;
    makeAutoObservable(this);
  }

  onClick = () => {
    if (this.isAlwaysSelected) return;
    this.isSelectedNow = !this.isSelectedNow;
  };

  deselect = () => {
    this.isSelectedNow = false;
  };

  get isSelected() {
    return this.isSelectedNow;
  }
}
