import React from "react";
import { Navigate } from "react-router-dom";

//Pages
import AnalyticsClass from "../Pages/Analytics/AnalyticsClass";
import AnalyticsCorpus from "../Pages/Analytics/AnalyticsCorpus";
import AnalyticsPerson from "../Pages/Analytics/AnalyticsPerson";
import Error404 from "../Pages/Error404";
import Instructions from "../Pages/Instructions";
import TeachingMaterials from "../Pages/TeachingMaterials";
import CamerasId from "../Pages/Сameras";

// Import страниц актификации
import { Analytics } from "src/Pages/Analytics";
import { Dashboard } from "src/Pages/Dashboard";
import { PaymentRate } from "src/Pages/Finance/PaymentRate";
import { UserWizard } from "src/Pages/Users/UserWizard";
import { UsersListPage } from "src/Pages/Users/UsersList";
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";
import UserProfile from "../Pages/Authentication/user-profile";
import CamerasClass from "../Pages/Сameras/CamerasClass";

//Роуты с аунтификацией
const authProtectedRoutes = [
  //pages
  // этот маршрут должен находиться в конце всех остальных маршрутов
  // Этот маршрут перенаправляет на /dashboard при вводе базового URL (пустой путь)
  {
    path: "/",
    component: <Navigate to="/dashboard" />,
  },
  { path: "/analytics", component: <Analytics /> },

  { path: "/cameras/:id", component: <CamerasId /> },
  { path: "/cameras/:id/:class", component: <CamerasClass /> },
  { path: "/teaching-materials", component: <TeachingMaterials /> },
  { path: "/instructions", component: <Instructions /> },
  { path: "/analytics/:id", component: <AnalyticsCorpus /> },
  { path: "/analytics/:id/:class", component: <AnalyticsClass /> },
  { path: "/analytics/:id/:class/:person", component: <AnalyticsPerson /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/finance/paymentRate", exact: true, component: <PaymentRate /> },

  { path: "/users/usersList", exact: true, component: <UsersListPage /> },
  {
    path: "/users",
    exact: true,
    component: <Navigate to="/users/usersList" />,
  },
  {
    path: "/users/:id",
    exact: false,
    component: <UserWizard />,
  },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  {
    path: "/finance",
    exact: true,
    component: <Navigate to="/finance/paymentRate" />,
  },
];

const publicRoutes = [
  // Страницы актификации (здесь можно создавать пустые страницы без сайдбаров примеры ниже) - публичные
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/loginMobile", component: <LoginMobile /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  { path: "/error404", component: <Error404 /> },

  // Перенаправление на /analytics для всех неизвестных маршрутов
  {
    path: "*",
    component: <Navigate to="/error404" />,
  },
];

export { authProtectedRoutes, publicRoutes };
