import React, { FC, memo, useMemo } from "react";
import { UserStatus } from "src/models/profile/User";

interface UserActionButtonProps {
  userStatus: UserStatus;
}

const UserActionButton: FC<UserActionButtonProps> = ({ userStatus }) => {
  const label = useMemo(() => {
    switch (userStatus) {
      case UserStatus.APPROVED:
        return "Активировать учетную запись";
      case UserStatus.REGISTRATION:
        return "Отказать в модерации ";
      default:
        return "Активировать учетную запись";
    }
  }, [userStatus]);
  return <button className="btn btn-danger">{label}</button>;
};
export default memo(UserActionButton);
