import React, { FC, memo } from "react";
import AppTabItem, { TabOption } from "./AppTabItem";

interface AppNavTabsProps {
  options: TabOption[];
}

const AppNavTabs: FC<AppNavTabsProps> = ({ options }) => {
  return (
    <ul className="nav nav-pills" role="tablist">
      {options.map((option, index) => (
        <AppTabItem key={index} option={option} />
      ))}
    </ul>
  );
};
export default memo(AppNavTabs);
