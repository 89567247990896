import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import { UserRoles } from "src/models/profile/UserRoles";
import ProfileService from "src/services/ProfileService";
import styles from "./Page.module.scss";

interface PageProps {
  children?: React.ReactNode;
  links?: { path: string; label: string }[];
  replaceNveLink?: string;
  accessRoles?: UserRoles[];
}

const Page: FC<PageProps> = ({
  children,
  links = [],
  replaceNveLink,
  accessRoles = [],
}) => {
  const { profile, isLoading } = ProfileService;

  const router = useNavigate();
  useLayoutEffect(() => {
    const needReplace =
      accessRoles.length > 0 &&
      !isLoading &&
      profile &&
      !accessRoles.includes(profile?.role);
    if (needReplace) {
      router(replaceNveLink || "/");
    }
  }, [accessRoles, isLoading, profile, replaceNveLink, router]);

  return (
    <div className={classNames("page-content mb-4", styles.container)}>
      <Container fluid={true}>
        <Breadcrumbs links={links} />
        <>{children}</>
      </Container>
    </div>
  );
};
export default observer(Page);
