import { makeAutoObservable, runInAction } from "mobx";
import Location from "src/models/location/Location";
import api from "./ApiService";

class LocationsService {
  constructor() {
    makeAutoObservable(this);
  }
  isLoading: boolean = false;
  locations: Location[] = [];

  getLocations = async () => {
    if (this.isLoading) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    const response = await api.get("/camera/locations");
    runInAction(() => {
      this.locations = response.data.locations;
      this.isLoading = false;
    });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LocationsService();
