import classNames from "classnames";
import React, { FC, memo } from "react";
import styles from "./AppCheckbox.module.scss";

interface AppCheckboxProps {
  value?: string | number;
  label?: string | number;
  onClick: (value?: string | number) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
}

const AppCheckbox: FC<AppCheckboxProps> = ({
  value,
  label,
  onClick,
  isChecked,
  isDisabled = false,
}) => {
  return (
    <div>
      <div className="form-check-label">
        <div
          className={classNames([
            "form-check-input m-0",
            styles.checkbox,
            {
              [styles.active]: isChecked,
            },
          ])}
          // checked={isChecked}
          onClick={() => {
            onClick(value);
          }}
        />
        {label}
      </div>
    </div>
  );
};
export default memo(AppCheckbox);
