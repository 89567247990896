import dayjs from "dayjs";
import React, { FC, memo } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { __LOGS__ } from "src/_mocks/logs";
import { AppCheckbox } from "src/components/Common/AppCheckbox";
import { EditButton } from "src/components/Common/EditButton";
import { User } from "src/models/profile/User";
import LogList from "./LogList";
import UserActionButton from "./UserActionButton";

interface UserCardProps {
  user?: User | null;
}

const UserCard: FC<UserCardProps> = ({ user }) => {
  if (!user) return null;
  return (
    <Card>
      <CardTitle className="font-weight-bold p-2">Пользователь</CardTitle>
      <CardBody>
        <Row>
          <Col xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <div>{user.name}</div>
              <EditButton onClick={() => {}} />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>{user.phone}</div>
              <AppCheckbox
                isDisabled
                value={user.id}
                isChecked
                onClick={() => {}}
              />
            </div>
            <div className="mt-4">
              <div className="d-flex align-items-center justify-content-start">
                <i className="ri ri-bookmark-3-line" />
                <div className="ml-1">
                  {user.created_at
                    ? dayjs(user.created_at).format("DD.MM.YYYY HH:mm:ss")
                    : "--/--"}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-start">
                <i className="ri ri-calendar-todo-line mr-3" />
                <div className="ml-1">
                  {user.updated_at
                    ? dayjs(user.updated_at).format("DD.MM.YYYY HH:mm:ss")
                    : "--/--"}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-start">
                <i className="ri ri-chat-delete-line mr-3" />
                <div className="ml-1">
                  {user.deleted_at
                    ? dayjs(user.deleted_at).format("DD.MM.YYYY HH:mm:ss")
                    : "--/--"}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} className="d-flex flex-column justify-content-between">
            <LogList logs={__LOGS__} />
            <UserActionButton userStatus={user.status} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default memo(UserCard);
