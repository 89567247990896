import React, { useEffect, useMemo, useState } from "react";

import { Alert, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import MyLoderChart from "../../components/Common/Loader/MyLoderChart";
import PersonTable from "../../components/Common/table/PersonTable";

import { observer } from "mobx-react-lite";
import { useSelector } from "react-redux";
import { AppInput } from "src/components/Common/AppInput";
import { AppSelector } from "src/components/Common/AppSelector";
import { AppModal } from "src/components/Common/Modal";
import Course, { Topic } from "src/models/analitics/Course";
import LocationAnalytics, {
  GroupAnalytic,
  UserAnalytic,
} from "src/models/analitics/LocationAnalytics";
import LocationsService from "src/services/LocationsService";
import MyPopup from "../../components/Common/ui/MyPopup";
import AnalyticsViewModel from "./AnalyticsViewModel";

const Cameras = () => {
  const {
    locationAnalytics,
    isLoading,
    getAnalyticsData,
    courseAnalytics,
    parentBrakeRuleFilter,
    sendNote,
    setActiveUserId,
    setTopicId,
    setNote,
    note,
  } = AnalyticsViewModel;
  const params = useParams();
  const error = useSelector((state: any) => state.analyticsReducer.error);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [topic, setTopic] = useState({}); //храним курс его значения для попапа
  const { locations, getLocations } = LocationsService;
  const [noteModal, setNoteModal] = useState(false);

  //выбранный корпус ищем и отрисосовываем через params
  let selectedLocation: LocationAnalytics | undefined;
  let selectGroup: GroupAnalytic | undefined | null;
  let selectUser: any;

  if (locationAnalytics) {
    selectedLocation = locationAnalytics.find(
      // @ts-ignore
      (data: LocationAnalytics) => data.id == params.id
    );
    selectGroup = selectedLocation
      ? selectedLocation.groups.find(
          // @ts-ignore
          (group: GroupAnalytic) => group.id == params.class
        )
      : null;
    selectUser = selectGroup?.users.find(
      (user: UserAnalytic) => user.name === params.person
    );
  }

  useEffect(() => {
    if (locationAnalytics.length === 0 && !isLoading) {
      getAnalyticsData();
    }
    if (!locations.length) {
      // getLocations();
    }
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Функция обратного вызова для получения topicId из PersonTable
  const handlePopupToggle = (courseId: number, topicId: number) => {
    togglePopup();
    const cours = courseAnalytics.find(
      (course: Course) => course.id === courseId
    );
    const topic =
      cours && cours.topics.find((topic: Topic) => topic.id === topicId);
    topic && setTopic(topic);
  };

  //brandcrumb
  const links = useMemo(() => {
    return [
      { path: "/", label: "Главная" },
      { path: "/analytics", label: "Аналитика" },
      { path: `/analytics/${params.id}`, label: selectedLocation?.name },
      {
        path: `/analytics/${params.id}/${params.class}`,
        label: selectGroup?.name,
      },
      { label: params.person },
    ];
  }, [
    params.class,
    params.id,
    params.person,
    selectGroup?.name,
    selectedLocation?.name,
  ]);

  document.title = `Аналитика | ${params.id} | ${params.class} | ${params.person}  `;
  const handleSendNote = async () => {
    await sendNote();
    setNoteModal(false);
  };
  return (
    <React.Fragment>
      <div className="page-content d-flex flex-column mb-4">
        <Container fluid={true}>
          <Breadcrumbs links={links} />
          <Row className="mt-2">
            {isLoading ? (
              [...Array(6)].map((_, index) => (
                <Col key={index} xl={4} lg={6} md={6}>
                  <MyLoderChart />
                </Col>
              ))
            ) : error ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных аналитики</Alert>
              </Col>
            ) : (
              <div
                className="flex-grow-1 d-flex flex-column"
                style={{ minHeight: "85vh" }}
              >
                {/* <MyFilter
                  filter={filter}
                  setFilter={setFilter}
                  options={options}
                  value={itemsPerPage}
                  onChange={(value) => setItemsPerPage(value)}
                /> */}
                <Col lg={12}>
                  {courseAnalytics.map((course: Course, index: number) => (
                    <div key={index}>
                      <PersonTable
                        noteButtonClick={(topicId, userId) => {
                          setNoteModal(true);
                          setActiveUserId(userId);
                          setTopicId(topicId);
                        }}
                        buttonClick={handlePopupToggle}
                        name={course.name}
                        selectedUser={selectUser}
                        courseAnalytics={courseAnalytics}
                      />
                      <div className="mb-4"></div>
                    </div>
                  ))}
                </Col>
              </div>
            )}
            <MyPopup isOpen={isPopupOpen} toggle={togglePopup} topic={topic} />
            <AppModal
              bgcolor="grey"
              title="Жалоба"
              isOpen={noteModal}
              onSubmit={handleSendNote}
              onClose={() => setNoteModal(false)}
              positiveButtonLabel="Отправить"
            >
              <AppSelector
                filter={parentBrakeRuleFilter}
                label="Родитель нарушил правило?:"
                onChange={() => {}}
              />
              <h6 className="mt-3">Текст жалобы:</h6>
              <AppInput
                isSearch={false}
                onChange={setNote}
                value={note}
                icon=""
              />
            </AppModal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default observer(Cameras);
