import React, { FC, memo } from "react";

interface DeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({ onClick }) => {
  return (
    <div className="d-flex align-items-center cursor-pointer" onClick={onClick}>
      <i className="mdi mdi-close" />
    </div>
  );
};
export default memo(DeleteButton);
