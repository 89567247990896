import Log from "src/models/profile/Logs";

export const __LOGS__: Log[] = [
  {
    id: 1,
    date: "10.01.2021",
    time: "15:00",
    description: "прошел модератором",
    color: "#f6c23e44",
  },
  {
    id: 2,
    date: "10.01.2021",
    time: "15:00",
    description: "Пользователь уснул",
    color: "#e74a3b44",
  },
  {
    id: 3,
    date: "10.01.2021",
    time: "15:00",
    description: "Пользователь сменил смену",
    color: "#4e73df44",
  },
];
