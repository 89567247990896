import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { OrbitProgress } from "react-loading-indicators";
import styles from "./Image.module.scss";

interface ImageProps {
  url: string;
}

const Image: FC<ImageProps> = ({ url }) => {
  const imgRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [bgStyle, setBgStyle] = useState({});

  const getImage = useCallback(async () => {
    if (!url) return;
    setLoading(true);
    const response = await fetch(url, {
      headers: {
        Appclient: "ANDROID",
        Appversion: process.env.REACT_APP_VERSION || "",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Ответ сети был не ok.");
    }
    const myBlob = await response.blob();
    const objectURL = URL.createObjectURL(myBlob);
    if (imgRef.current) {
      setBgStyle({ backgroundImage: `url( ${objectURL} )` });
      imgRef.current.backgroundImage = `url( ${objectURL} )`;
    }
    setLoading(false);
  }, [url]);

  useEffect(() => {
    getImage();
  }, [url]);

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <OrbitProgress color="#0bb197" size="medium" text="" textColor="" />
        </div>
      )}
      <div style={bgStyle} ref={imgRef} className={styles.imgWrapper}>
        {/* <img
          defaultValue={""}
          ref={imgRef}
          className={styles.img}
          alt={"img"}
          src={""} */}
        {/* /> */}
      </div>
    </>
  );
};
export default memo(Image);
