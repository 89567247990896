import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserRoles } from "src/models/profile/UserRoles";
import AppService from "src/services/AppService";
import ProfileService from "src/services/ProfileService";
import styles from "./MenuNavItem.module.scss";
import { MenuNames } from "./constants";

export interface IMenuItem {
  id?: string;
  sublabel: string;
  link?: string;
  subMenu?: { title: string; link: string }[];
  availableRoles?: UserRoles[];
}
export interface ISideMenu {
  id?: string;
  label: MenuNames;
  icon: string;
  url?: string;
  isHasArrow?: boolean;
  bgcolor?: string;
  subItem?: IMenuItem[];
  isMainMenu?: boolean;
  issubMenubadge?: boolean;
  badgeValue?: number;
  onClick?: () => void;
}

interface MenuNavItemProps {
  item: ISideMenu | null;
}

const MenuNavItem: FC<MenuNavItemProps> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = ProfileService;
  const { setActiveMenuName } = AppService;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const handleClick = useCallback(
    (label: string) => {
      if (item?.url) {
        navigate(item?.url);
      }
      setActiveMenuName(label);
      setIsOpen((prev) => !prev);
    },
    [item?.url, navigate, setActiveMenuName]
  );

  useEffect(() => {
    if (item?.subItem?.length) {
      let isActualActive = false;
      item?.subItem?.some((subItem) => {
        if (subItem.link === location.pathname) {
          setIsOpen(true);
          setIsActive(true);
          isActualActive = true;
          return true;
        }
      });

      if (!isActualActive) {
        setIsActive(false);
      }
    } else {
      if (
        (item?.url && location.pathname.includes(item?.url)) ||
        location.pathname === item?.url
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [item?.subItem, item?.url, location.pathname]);

  if (!item) return null;
  return (
    <>
      {item.isMainMenu ? (
        <li className="menu-title">{item.label}</li>
      ) : (
        <li key={item.label}>
          <Link
            onClick={() => handleClick(item.label)}
            className={classNames(
              styles.item,
              item.issubMenubadge || item.isHasArrow
                ? " " + styles.hasArrow
                : " ",
              isOpen && styles.active,
              isActive ? "mm-active" : ""
            )}
            to={item.url ? item.url : "/#"}
          >
            <i className={item.icon} style={{ marginRight: "5px" }}></i>
            {item.issubMenubadge && (
              <span className={"badge rounded-pill float-end " + item.bgcolor}>
                {" "}
                {item.badgeValue}{" "}
              </span>
            )}
            <span>{item.label}</span>
          </Link>
          {/* </span> */}
          {!!item.subItem && (
            <ul
              className={classNames(
                "sub-menu",
                isOpen ? styles.active : "mm-collapse"
              )}
            >
              {item.subItem.map((item, key) => {
                if (
                  (item.availableRoles &&
                    profile &&
                    item.availableRoles.includes(profile.role)) ||
                  !item.availableRoles
                ) {
                  return (
                    <SubItemMenu
                      isActive={location.pathname === item?.link}
                      item={item}
                      key={key}
                    />
                  );
                }
              })}
            </ul>
          )}
        </li>
      )}
    </>
  );
};
export default observer(MenuNavItem);

const SubItemMenu: FC<{ item: IMenuItem; isActive?: boolean }> = ({
  item,
  isActive,
}) => {
  return (
    <li className={isActive ? "mm-active" : " "}>
      <Link
        to={item.link || "#"}
        className={item.subMenu && "has-arrow waves-effect"}
      >
        {item.sublabel}
      </Link>
      {item.subMenu && (
        <ul className="sub-menu">
          {item.subMenu.map((item, key) => (
            <li key={key}>
              <Link to="#">{item.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
