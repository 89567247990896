import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./AppModal.module.scss";

export interface ModalProps {
  children?: ReactNode;
  onClose: () => void;
  isOpen?: boolean;
  onSubmit?: () => void;
  title?: string;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  withButtons?: boolean;
  bgcolor?: "white" | "grey";
  stylesExtra?: {
    body?: string;
    content?: string;
  };
  submitDisabled?: boolean;
  isLoading?: boolean;
}

const AppModal: FC<ModalProps> = ({
  children,
  onClose,
  isOpen,
  onSubmit,
  title,
  positiveButtonLabel,
  negativeButtonLabel,
  withButtons = true,
  stylesExtra,
  bgcolor = "white",
  submitDisabled = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.wrapper + (isOpen ? ` ${styles.show}` : "")}>
      <div
        id="myModal"
        className={"modal fade " + (isOpen ? "show" : "")}
        style={{ display: isOpen ? "block" : "none" }}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div
            style={{ backgroundColor: bgcolor === "grey" ? "#f5f5f5" : "#fff" }}
            className={classNames("modal-content", stylesExtra?.content)}
          >
            <div className="modal-header">
              {!!title && (
                <h5 className="modal-title" id="myModalLabel">
                  {title}
                </h5>
              )}
              <button
                onClick={onClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={classNames("modal-body", stylesExtra?.body)}>
              {children}
            </div>
            {withButtons && (
              <div className="modal-footer">
                <button
                  onClick={onClose}
                  type="button"
                  className="btn btn-secondary waves-effect"
                  data-bs-dismiss="modal"
                >
                  {negativeButtonLabel || "Закрыть"}
                </button>
                <button
                  disabled={submitDisabled}
                  onClick={onSubmit}
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  {positiveButtonLabel || "Сохранить"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppModal;
