import React, { useEffect, useState } from "react";
import Routes from "./Routes/index";

// Import Scss
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/theme.scss";
import LocationsService from "./services/LocationsService";
import ProfileService from "./services/ProfileService";
import { checkAuthAction } from "./store/myAuth/login/actions";
import { useAppDispatch } from "./store/storeTypes";

function App() {
  const dispatch = useAppDispatch();
  const { getProfile } = ProfileService;
  const { getLocations } = LocationsService;
  const [isTokenChecked, setIsTokenChecked] = useState(false); // Хранит информацию о проверке токена

  useEffect(() => {
    // Проверяем наличие токена в localStorage
    const token = localStorage.getItem("token");
    if (token) {
      Promise.all([getProfile()]);
      // Если токен найден, вызываем checkAuthAction() и устанавливаем isTokenChecked в true
      // getProfile();
      dispatch(checkAuthAction());
      setIsTokenChecked(true);
      // getLocations();
      // dispatch(fetchAnalytics());
      // dispatch(fetchCameras());
    } else {
      // Если токен не найден, просто устанавливаем isTokenChecked в true
      setIsTokenChecked(true);
    }
  }, []); // При изменении dispatch, т.е. при монтировании компонента

  // Пока не проверен токен, отображаем загрузочный экран или что-то другое
  if (!isTokenChecked) {
    return <div>Загрузка...</div>;
  }

  return (
    <React.Fragment>
      <Routes />
      <ToastContainer />
    </React.Fragment>
  );
}

export default observer(App);
