import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Group } from "src/models/location/Location";
import { filterCameras } from "../../../store/сameras/actions";

interface MyFiltersCamerasProps {
  selectedGroup?: Group;
  leftElement?: JSX.Element;
}
const MyFiltersCameras: FC<MyFiltersCamerasProps> = ({
  selectedGroup,
  leftElement,
}) => {
  // Получение текущей даты и времени в Московском времени
  const currentDate = new Date();
  const moscowTime = new Date(currentDate.getTime() + 3 * 60 * 60 * 1000);

  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = currentDate.getDate().toString().padStart(2, "0");

  const currentHours = currentDate.getUTCHours() + 3;
  const currentMinutes = currentDate.getUTCMinutes();

  // Состояние для хранения выбранной даты (по умолчанию - сегодняшняя дата)
  const [selectedDate, setSelectedDate] = useState(
    `${currentYear}-${currentMonth}-${currentDay}`
  );

  // Состояние для хранения времени начала (по умолчанию - округленное текущее время)
  const [startTime, setStartTime] = useState("00:00");

  // Состояние для хранения времени окончания (по умолчанию - "00:00")
  const [endTime, setEndTime] = useState("23:00");

  const dispatch = useDispatch();

  const id = selectedGroup?.cameras[0]?.id; // id камеры
  const additionalParams = {
    locationId: selectedGroup?.id,
    dateFrom: `${selectedDate} ${startTime}:00`,
    dateTo: `${selectedDate} ${endTime}:00`,
    offset: 0,
    limit: 30,
  };

  useEffect(() => {
    if (id && additionalParams) {
      dispatch(filterCameras(id, additionalParams));
    }
  }, []);

  // Определение временных опций с интервалом каждый час от 00:00 до 23:00
  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    const time = `${hour}:00`;
    timeOptions.push({ value: time, name: time });
  }

  // Обработчик изменения выбранной даты
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  // Обработчик изменения времени начала
  const handleStartTimeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = event.target.value;

    console.log(newStartTime);
    // const [newHour] = newStartTime.split(":");
    // const roundedHour = parseInt(newHour, 10);
    setStartTime(newStartTime);
  };

  // Обработчик изменения времени окончания
  const handleEndTimeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newEndTime = event.target.value;
    if (newEndTime !== startTime && newEndTime > startTime) {
      setEndTime(newEndTime);
    }
  };
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {/* <CardTitle style={{ fontWeight: 500 }} className="h3 mb-3">
                Архив записей
              </CardTitle> */}
              <div
                className={`d-flex  ${
                  leftElement
                    ? "justify-content-between"
                    : "justify-content-end"
                }`}
              >
                {leftElement && leftElement}
                <div className="d-flex">
                  {" "}
                  <div className="d-flex align-items-center">
                    <p
                      style={{
                        color: "#A9A9A9",
                        marginRight: 10,
                        marginBottom: 0,
                      }}
                    >
                      Дата
                    </p>
                    <input
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        borderColor: "#A9A9A9",
                      }}
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                    />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: 20 }}
                  >
                    <p
                      style={{
                        color: "#A9A9A9",
                        marginRight: 10,
                        marginBottom: 0,
                      }}
                    >
                      Начало
                    </p>
                    {/* Выпадающий список начала временного интервала */}
                    <select
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        borderColor: "#A9A9A9",
                      }}
                      className="form-control select-with-arrow"
                      value={startTime}
                      onChange={handleStartTimeChange}
                    >
                      {timeOptions
                        .filter(
                          (option) =>
                            option.value < endTime && option.value !== "23:00"
                        )
                        .map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: 20 }}
                  >
                    <p
                      style={{
                        color: "#A9A9A9",
                        marginRight: 10,
                        marginBottom: 0,
                      }}
                    >
                      Конец
                    </p>
                    {/* Выпадающий список конца временного интервала */}
                    <select
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        borderColor: "#A9A9A9",
                      }}
                      className="form-control select-with-arrow"
                      value={endTime}
                      onChange={handleEndTimeChange}
                    >
                      {timeOptions
                        .filter((option) => option.value > startTime)
                        .map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <button
                    onClick={() =>
                      dispatch(filterCameras(id, additionalParams))
                    }
                    style={{ marginLeft: 30 }}
                    className="btn btn-primary"
                  >
                    Поиск
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MyFiltersCameras;
