import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, FC, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { AppInput } from "src/components/Common/AppInput";
import AppRadioButton from "src/components/Common/AppRadioButton/AppRadioButton";
import { AppSelector } from "src/components/Common/AppSelector";
import { OptionModel } from "src/models/common/FilterModel";
import UsersListViewModel from "../UsersListViewModel";
import styles from "./UsersTableHeader.module.scss";

interface UsersFilterProps {
  vm: UsersListViewModel;
}

const UsersFilter: FC<UsersFilterProps> = ({ vm }) => {
  const {
    citiesFilter,
    locationFilter,
    groupClassFilter,
    blockFilter,
    trialPlusFilter,
    balancePlusFilter,
    restOfDaysFilter,
    restOfTrialFilter,
    blockedReasonFilter,
    setDateFilter,
    setEndDateFilter,
    endDateFilter,
    dateFilter,
    roleFilter,
    tariffFilter,
    statusFilter,
    applyFilter,
  } = vm;

  const handleDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDateFilter(event.target.value);
    },
    [setDateFilter]
  );
  const handleEndDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEndDateFilter(event.target.value);
    },
    [setEndDateFilter]
  );

  return (
    <div>
      <Row className="mb-3">
        <Col xl={3}>
          <AppSelector
            label="Город"
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={citiesFilter}
            placeholder="Город"
          />
        </Col>
        <Col xl={3}>
          <AppSelector
            label="Статус "
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={statusFilter}
            placeholder="Статус "
          />
        </Col>
        <Col xl={3}>
          <AppRadioButton filter={blockFilter} />
        </Col>
        <Col xl={3}>
          <AppSelector
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={blockedReasonFilter}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={3}>
          <AppSelector
            label="Школа/сад
            "
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={locationFilter}
            placeholder="Школа/сад
            "
          />
        </Col>
        <Col xl={3}>
          <AppSelector
            label="Роль"
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={roleFilter}
            placeholder="Роль"
          />
        </Col>
        <Col xl={3}>
          <AppRadioButton filter={balancePlusFilter} />
        </Col>
        <Col xl={3}>
          <AppSelector
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={restOfDaysFilter}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={3}>
          <AppSelector
            isDisabled={locationFilter.selectedOptions.length === 0}
            label="Группа/класс"
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={groupClassFilter}
            placeholder="Группа/класс"
          />
        </Col>
        <Col xl={3}>
          <AppSelector
            label="Тариф"
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={tariffFilter}
            placeholder="Тариф"
          />
        </Col>
        <Col xl={3}>
          <AppRadioButton filter={trialPlusFilter} />
        </Col>
        <Col xl={3}>
          <AppSelector
            onChange={function (option: OptionModel): void {
              throw new Error("Function not implemented.");
            }}
            filter={restOfTrialFilter}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={6}>
          <AppInput
            onChange={vm.setSearch}
            value={vm.search}
            label="Найти по:"
          />
        </Col>
        <Col xl={6}>
          <Row>
            <Col>
              <div
                className={classNames(
                  "d-flex align-items-center",
                  styles.dates
                )}
              >
                <p
                  style={{
                    marginRight: 10,
                    marginBottom: 0,
                  }}
                >
                  Создан от
                </p>
                <input
                  defaultValue={dateFilter}
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    minWidth: "200px",
                    borderColor: "#A9A9A9",
                  }}
                  type="datetime-local"
                  value={dateFilter}
                  onChange={handleDateChange}
                  className="form-control"
                />
                <div className="d-flex align-items-center">
                  <p
                    style={{
                      marginRight: 10,
                      marginBottom: 0,
                    }}
                  >
                    до
                  </p>
                  <input
                    defaultValue={endDateFilter}
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      minWidth: "200px",
                      borderColor: "#A9A9A9",
                    }}
                    type="datetime-local"
                    value={endDateFilter}
                    onChange={handleEndDateChange}
                    className="form-control"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <div className="d-flex align-items-center">
            <div>Найдено: </div>
            {""}
            <div style={{ marginLeft: 10, fontSize: 16 }}>{vm.totalCount}</div>
          </div>
        </Col>
        <Col xl={8} className="d-flex justify-content-end">
          <button onClick={applyFilter} className="btn btn-primary">
            Найти
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default observer(UsersFilter);
