import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useRef, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { AppSwitch } from "src/components/Common/AppSwitch";
import { DeleteButton } from "src/components/Common/DeleteButton";
import { AppModal } from "src/components/Common/Modal";

const __MODAL_MESSAGES__ = {
  delete: "Вы уверены, что хотите удалить подписчика?",
  disable: "Вы уверены, что хотите отключить подписчика?",
  enable: "Вы уверены, что хотите включить подписчика?",
};

interface FollowersCardProps {}

interface Follower {
  id: number;
  name: string;
  phone: string;
  created_at: string;
  status: string;
  isActive: boolean;
}

const FollowersCard: FC<FollowersCardProps> = ({}) => {
  const [followers, setFollowers] = useState<Follower[]>([
    {
      id: 1,
      name: "Ева",
      phone: "+7 (999) 999-99-99",
      created_at: "2021-01-01",
      status: "Жена",
      isActive: true,
    },
    {
      id: 2,
      name: "Adam",
      phone: "+7 (999) 999-99-99",
      created_at: "2021-01-01",
      status: "Тесть",
      isActive: false,
    },
    {
      id: 3,
      name: "Ева2",
      phone: "+7 (999) 999-99-99",
      created_at: "2021-01-01",
      status: "Жена",
      isActive: false,
    },
  ]);

  const modalQuestion = useRef<string>("");
  const activeFollower = useRef<Follower>();
  const [viewModal, setViewModal] = React.useState(false);

  const toggleEnableFollower = useCallback(
    (follower: Follower) => {
      activeFollower.current = follower;
      setViewModal(true);
      if (follower.isActive) {
        modalQuestion.current = __MODAL_MESSAGES__.disable;
      } else {
        modalQuestion.current = __MODAL_MESSAGES__.enable;
      }
    },
    [followers]
  );

  const onDeleteFollower = useCallback(
    (follower: Follower) => {
      activeFollower.current = follower;
      modalQuestion.current = __MODAL_MESSAGES__.delete;
      setViewModal(true);
    },
    [followers]
  );

  const onSubmitModal = useCallback(() => {
    if (modalQuestion.current === __MODAL_MESSAGES__.disable) {
      setViewModal(false);
      activeFollower &&
        activeFollower.current &&
        (activeFollower.current.isActive = false);
    }

    if (modalQuestion.current === __MODAL_MESSAGES__.enable) {
      setViewModal(false);
      activeFollower &&
        activeFollower.current &&
        (activeFollower.current.isActive = true);
    }

    if (modalQuestion.current === __MODAL_MESSAGES__.delete) {
      setViewModal(false);
      setFollowers(
        followers.filter((f) => f.id !== activeFollower.current?.id)
      );
    }
  }, [followers]);

  return (
    <Card>
      <CardTitle className="font-weight-bold p-2">Подписчики</CardTitle>
      <CardBody>
        {followers.length === 0 && <div>У пользователя нет подписчиков</div>}
        {followers.map((follower) => (
          <Row className="mb-2" key={follower.id}>
            <Col xl={8}>
              <Row>
                <Col xl={12}>{follower.name}</Col>
              </Row>
              <Row>
                <Col xl={12}>{follower.phone}</Col>
              </Row>
            </Col>
            <Col xl={2}>{follower.status}</Col>
            <Col xl={1}>
              <AppSwitch
                onClick={() => toggleEnableFollower(follower)}
                isChecked={follower.isActive}
              />
            </Col>
            <Col xl={1}>
              <DeleteButton
                onClick={() => {
                  onDeleteFollower(follower);
                }}
              />
            </Col>
          </Row>
        ))}
        <Row>
          <Col className="d-flex align-items-center justify-content-end mt-3">
            <button className="btn btn-primary">Добавить подписчика</button>
          </Col>
        </Row>
      </CardBody>
      <AppModal
        isOpen={viewModal}
        onClose={() => setViewModal(false)}
        onSubmit={onSubmitModal}
        positiveButtonLabel="Да"
        negativeButtonLabel="Нет"
      >
        {modalQuestion.current}
      </AppModal>
    </Card>
  );
};
export default observer(FollowersCard);
