// reducer.js
import {
  GET_CAMERAS_SUCCESS,
  GET_CAMERAS_FAIL,
  SET_CAMERAS_LOADING,
  LOGOUT_USER_CAMERAS,
  SET_FILTER_CAMERAS_LOADING,
  GET_FILTER_CAMERAS_SUCCESS,
  GET_FILTER_CAMERAS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  camerasData: [],
  error: null,
  loading: false, // Добавляем новое состояние loading
  filterCamerasData: [],
  loadingFilter: false,
  errorFilter: null,
};

const camerasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMERAS_SUCCESS:
      return {
        ...state,
        camerasData: action.payload,
        error: null,
        loading: false,
      };

    case GET_FILTER_CAMERAS_SUCCESS:
      return {
        ...state,
        filterCamerasData: action.payload,
        errorFilter: null,
        loadingFilter: false,
      };

    case GET_CAMERAS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false, // Устанавливаем loading в false в случае ошибки
      };

    case GET_FILTER_CAMERAS_FAIL:
      return {
        ...state,
        errorFilter: action.payload,
        loadingFilter: false,
      };
    case SET_CAMERAS_LOADING: // Обрабатываем новый тип действия для установки состояния загрузки
      return {
        ...state,
        loading: action.payload,
      };

    case SET_FILTER_CAMERAS_LOADING: //состояние загрузки для фильтров
      return {
        ...state,
        loadingFilter: action.payload,
      };

    case LOGOUT_USER_CAMERAS: // Обрабатываем действие выхода пользователя
      return INIT_STATE;
    default:
      return state;
  }
};

export default camerasReducer;
