import { PeriodNames } from "src/models/dashboard/IDashboard";

export const _CHART_PERIODS = [
  {
    value: PeriodNames.MONTH,
    label: "1М",
  },
  {
    value: PeriodNames.HALF_YEAR,
    label: "6М",
  },
  {
    value: PeriodNames.YEAR,
    label: "1Г",
  },
];

export const _CHART_DATA = {
  [PeriodNames.MONTH]: {
    chartLabels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    data: [
      {
        name: "Новые",
        type: "column",
        data: [
          40, 32, 42, 34, 44, 36, 46, 38, 48, 40, 52, 42, 54, 44, 56, 46, 58,
          48, 60, 50, 62, 52, 64, 54, 66, 56, 68, 58, 70, 60, 72,
        ],
      },
      {
        name: "Повторные",
        type: "area",
        data: [
          40, 32, 42, 34, 44, 36, 46, 38, 48, 40, 52, 42, 54, 44, 56, 46, 58,
          48, 60, 50, 62, 52, 64, 54, 66, 56, 68, 58, 70, 60, 72,
        ],
      },
      {
        name: "Упущенные",
        type: "line",
        data: [
          40, 32, 42, 34, 44, 36, 46, 38, 48, 40, 52, 42, 54, 44, 56, 46, 58,
          48, 60, 50, 62, 52, 64, 54, 66, 56, 68, 58, 70, 72, 62,
        ],
      },
      {
        name: "Возможные",
        type: "column",
        data: [
          40, 32, 42, 34, 44, 36, 46, 38, 48, 40, 52, 42, 54, 44, 56, 46, 58,
          48, 60, 50, 62, 52, 64, 54, 66, 56, 58, 70, 60, 72, 62,
        ],
      },
    ],
  },
  [PeriodNames.HALF_YEAR]: {
    chartLabels: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн"],
    data: [
      {
        name: "Новые",
        type: "column",
        data: [68, 58, 70, 60, 72, 62],
      },
      {
        name: "Повторные",
        type: "area",
        data: [40, 32, 42, 34, 44, 36],
      },
      {
        name: "Упущенные",
        type: "line",
        data: [52, 42, 54, 44, 56, 46],
      },
      {
        name: "Возможные",
        type: "column",
        data: [52, 42, 54, 44, 56, 46],
      },
    ],
  },
  [PeriodNames.YEAR]: {
    chartLabels: [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
    data: [
      {
        name: "Новые",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 18],
      },
      {
        name: "Повторные",
        type: "area",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
      },
      {
        name: "Упущенные",
        type: "line",
        data: [44, 55, 41, 42, 22, 43, 21, 41, 56, 27, 43, 27],
      },
      {
        name: "Возможные",
        type: "column",
        data: [44, 55, 41, 42, 22, 43, 21, 41, 56, 27, 43, 27],
      },
    ],
  },
};
