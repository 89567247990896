import GetUsersDTO, { UsersResponse } from "src/models/users/GetUsersDTO";
import ISearchForm from "src/models/users/ISearchForm";
import api from "src/services/ApiService";

class UserAPI {
  static async getUsersSearchFormData(): Promise<ISearchForm> {
    const response = await api.get("/users/search");
    return response.data.form[0];
  }

  static async getUsers(params: GetUsersDTO): Promise<UsersResponse> {
    const response = await api.post("/users/search", params);

    return response.data;
  }
}

export default UserAPI;
