import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { runInAction } from "mobx";
import ProfileService from "src/services/ProfileService";
import {
  deleteJwtLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import { logoutUserAnalitics } from "../../analytics/actions";
import { CHECK_AUTH, MY_LOGIN_USER, MY_LOGOUT_USER } from "./actionTypes";
import {
  myAuth_apiError,
  myAuth_loginSuccess,
  myAuth_logoutUserSuccess,
} from "./actions";

function* myLoginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      phone: user.phone,
      password: user.password,
    });

    localStorage.setItem("token", response.data.session.token);
    runInAction(() => {
      ProfileService.profile = response.data.user;
    });
    yield put(myAuth_loginSuccess(response)); //если нужно показать инфо о пользователе пеедать сюда
    history("/");
  } catch (error) {
    yield put(myAuth_apiError(error));
  }
}

function* myLogoutUser({ payload: { history } }) {
  try {
    const response = yield call(deleteJwtLogin);
    localStorage.removeItem("token");
    yield put(myAuth_logoutUserSuccess());
    yield put(logoutUserAnalitics()); //срос состояния аналитики
    history("/login");
  } catch (error) {
    yield put(myAuth_apiError(error));
  }
}

function* checkAuth() {
  try {
    //если нужен будет рефреш токен разкоментировать
    // const response = axios.get(`${API_URL}/refresh`, { withCredentials: true});
    // localStorage.setItem("token", response.data.session.token);
    yield put(myAuth_loginSuccess());
  } catch (error) {
    yield put(myAuth_apiError(error));
  }
}

function* myAuthSaga() {
  yield takeEvery(MY_LOGIN_USER, myLoginUser);
  yield takeEvery(MY_LOGOUT_USER, myLogoutUser);
  yield takeEvery(CHECK_AUTH, checkAuth);
}

export default myAuthSaga;
