import { makeAutoObservable, runInAction } from "mobx";
import { _USERS_MOCK_DATA } from "src/_mocks/usersList";
import { User, UserLocation } from "src/models/profile/User";

class UserWizardViewModel {
  constructor(id?: string) {
    makeAutoObservable(this);
    if (id) {
      this.currentUser = _USERS_MOCK_DATA.find((u) => u.id === +id) || null;
      this.getCurrentUSer(+id);
    }
  }
  currentUser: User | null = null;
  locations: UserLocation[] = [];

  getCurrentUSer = async (id: number) => {
    runInAction(() => {
      this.currentUser =
        _USERS_MOCK_DATA.find((u) => u.id === id) || _USERS_MOCK_DATA[0];
      this.locations =
        _USERS_MOCK_DATA.find((u) => u.id === id)?.locations || [];
    });
  };

  deleteLocation = (id: number) => {
    this.locations = this.locations.filter((l) => l.id !== id);
  };
}

export default UserWizardViewModel;
