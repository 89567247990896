import classNames from "classnames";
import React, { FC, InputHTMLAttributes, memo, useState } from "react";
import styles from "./AppInput.module.scss";

export interface AppSelectOption {
  label: string;
  value: string | number;
}

interface AppInputProps {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  icon?: string;
  isSearch?: boolean;
  showIcon?: boolean;
  topLabel?: string;
  options?: AppSelectOption[];
  isDisabled?: boolean;
  props?: InputHTMLAttributes<HTMLInputElement>;
}

const AppInput: FC<AppInputProps> = ({
  value,
  onChange,
  label,
  placeholder,
  icon = "ri-search-line",
  isSearch = true,
  showIcon = true,
  topLabel,
  options,
  isDisabled = false,
  props,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (item: AppSelectOption) => {};
  return (
    <div className={"w-100"}>
      {!!topLabel && <h6 className={styles.label}>{topLabel}</h6>}
      <div className={classNames("d-flex align-items-center", styles.wrapper)}>
        {!!label && <span className={styles.label}>{label}</span>}
        <div
          className={classNames(
            `${isSearch && "app-search p-0 position-relative"} `,
            styles.input
          )}
        >
          <input
            disabled={isDisabled}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            onChange={(e) => onChange && onChange(e.target.value)}
            type="text"
            className={classNames(
              "form-control form-control-search",
              styles.input
            )}
            placeholder={placeholder}
            value={value || ""}
            style={{ background: "white" }}
            {...props}
          />
          {!!showIcon && <span className={icon}></span>}
        </div>
        {!!options && isOpen && (
          <div className={classNames(styles.modalMenu)}>
            {options.map((item, index) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item);
                }}
                className={classNames([
                  styles.dropdownItem,
                  { [styles.isSelected]: item.value === value },
                ])}
                key={index}
              >
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(AppInput);
