import React, { FC, memo, useCallback } from "react";
import DropDown from "../ui/DropDown";
import MySearch from "../ui/MySearch";
import DisplayPerPageFilter from "./DisplayPerPageFilter";

export interface Filter {
  sort: string;
  query: string;
}

export interface SelectOption {
  value: string;
  name: string;
}
interface MyFilterProps {
  filter: Filter;
  setFilter: (filter: Filter) => void;
  options: SelectOption[];
  pageValue: number;
  onChangePage: (count: number) => void;
  defaultValue?: string;
}

const MyFilter: FC<MyFilterProps> = ({
  filter,
  setFilter,
  options,
  pageValue,
  onChangePage,
  defaultValue,
}) => {
  const handleChangeSort = useCallback(
    (selectedSort: string) => setFilter({ ...filter, sort: selectedSort }),
    [filter, setFilter]
  );
  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <MySearch
        value={filter.query}
        onChange={(e: any) => setFilter({ ...filter, query: e.target.value })}
      />
      <div className="d-flex align-items-center">
        <DropDown
          name="Cортировка"
          value={filter.sort}
          onChange={handleChangeSort}
          options={options}
          defaultValue={defaultValue}
        />
        <DisplayPerPageFilter value={pageValue} onChange={onChangePage} />
      </div>
    </div>
  );
};

export default memo(MyFilter);
