import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { AddCommentContext } from "../Context";
import styles from "../PaymentRate.module.scss";
import PaymentRateViewModel from "../PaymentRateViewModel";
import { ColumnOption, TableItem } from "./types";

interface TableRowProps {
  typeView?: "odd" | "even";
  data: TableItem;
  hasChildren?: boolean;
  itemIndex?: number;
  isVisible?: boolean;
  firstLabel?: string;
  options: ColumnOption[];
}

const TableRow: FC<TableRowProps> = ({
  data,
  hasChildren,
  itemIndex = 0,
  isVisible,
  firstLabel,
  options,
  typeView = "even",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggleShow = useCallback(() => {
    if (!hasChildren) return;
    setIsOpen(!isOpen);
  }, [hasChildren, isOpen]);

  return (
    <>
      <tr className={`${styles[typeView]}`}>
        {data.data.map((option, index) => (
          <TableCell
            options={options}
            option={option}
            onClick={handleToggleShow}
            hasChildren={!!hasChildren}
            index={index}
            itemIndex={itemIndex}
            key={option.value}
            isOpen={!!isOpen}
            datatype={`data-${data.id}-${itemIndex}`}
            firstLabel={firstLabel || ""}
            rowId={data.id}
          />
        ))}
      </tr>
      {isOpen &&
        hasChildren &&
        data.items.map((item, index) => (
          <TableRow
            isVisible={false}
            key={item.locationName}
            hasChildren={!!item.items.length}
            typeView={item.className}
            data={item}
            itemIndex={itemIndex + 1}
            firstLabel={item.locationName}
            options={options}
          />
        ))}
    </>
  );
};
export default observer(TableRow);

const TableCell: FC<{
  option: ColumnOption;
  onClick: () => void;
  hasChildren: boolean;
  isOpen: boolean;
  index?: number;
  itemIndex?: number;
  datatype: string;
  firstLabel: string;
  options: ColumnOption[];
  rowId?: string;
}> = observer(
  ({
    option,
    onClick,
    hasChildren,
    isOpen,
    index = 0,
    itemIndex = 0,
    datatype,
    firstLabel,
    options,
    rowId,
  }) => {
    const { setEditableUserId } = PaymentRateViewModel;
    const [isVisibleModal, setIsVisibleModal] = useContext(AddCommentContext);
    const isSelected = useMemo(() => {
      const hasOption = options.find((item) => item.label === option.label);
      return !!hasOption;
    }, [option.label, options]);

    const handleEdit = () => {
      if (!option.isEditable) return;
      setIsVisibleModal(true);
      rowId && setEditableUserId(rowId);
    };

    return (
      <td
        onClick={handleEdit}
        style={{
          display: isSelected ? "table-cell" : "none",
        }}
        datatype={datatype}
        colSpan={option.countOfColumn}
        key={index}
        className={option.className}
      >
        <div
          onClick={onClick}
          className={classNames("d-flex justify-content-between")}
        >
          <div className={classNames("d-flex justify-content-between")}>
            <div
              style={{
                paddingLeft: !index ? itemIndex * 10 + "px" : "0px",
              }}
            ></div>
            <div className={"font-weight-bold"}>
              {index ? option.value : firstLabel}
              {/* {option.isEditable && option.label === "Комментарий" && (
              <div>
                {option.value ? (
                  <i className="mdi mdi-pencil" />
                ) : (
                  <i className="mdi mdi-plus" />
                )}
              </div>
            )} */}
            </div>
          </div>
          {hasChildren && !index && (
            <i
              className={`mdi ${
                !isOpen ? "mdi-chevron-down" : "mdi-chevron-up"
              } `}
            />
          )}
        </div>
      </td>
    );
  }
);
