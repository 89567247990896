import { ISideMenu } from "./MenuNavItem";

export const enum MenuNames {
  ANALYTICS = "Аналитика",
  USERS = "Пользователи",
  FINANCE = "Финансы",
  CAMERAS = "Мои камеры",
  TEACHING_MATERIALS = "Метод материалы",
  INSTRUCTIONS = "Инструкции",
  SUBSCRIPTIONS = "Подписки",
}

//данные в сайдбар
export const __SidebarMenu__: ISideMenu[] = [
  {
    label: MenuNames.ANALYTICS,
    id: "analytics",
    icon: "bx bx-bar-chart",
    url: "/analytics",
    bgcolor: "bg-primary",
  },
  // {
  //   label: MenuNames.USERS,
  //   id: "users",
  //   icon: "bx bx-group",
  //   isHasArrow: true,
  //   subItem: [
  //     {
  //       id: "usersList",
  //       sublabel: "Список пользователей",
  //       link: "/users/usersList",
  //       availableRoles: [UserRoles.SUPERADMIN],
  //     },
  //   ],
  // },
  // {
  //   label: MenuNames.FINANCE,
  //   id: "finance",
  //   icon: "bx bx-dollar",
  //   isHasArrow: true,
  //   subItem: [
  //     {
  //       id: "paymentRate",
  //       sublabel: "Норма прибыли",
  //       link: "/finance/paymentRate",
  //       availableRoles: [UserRoles.SUPERADMIN],
  //     },
  //   ],
  // },
  {
    label: MenuNames.CAMERAS,
    icon: "bx bx-video",
    id: "cameras",
    isHasArrow: true,
    subItem: [],
  },

  // {
  //   label: MenuNames.TEACHING_MATERIALS,
  //   id: "teaching-materials",
  //   icon: "bx bx-book-bookmark",
  //   url: "/teaching-materials",
  //   bgcolor: "bg-primary",
  // },

  // {
  //   label: MenuNames.INSTRUCTIONS,
  //   id: "instructions",
  //   icon: "bx bx-error-circle",
  //   url: "/instructions",
  //   bgcolor: "bg-primary",
  // },
];
