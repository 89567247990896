import React, { FC, memo, useCallback, useRef, useState } from "react";
import { OrbitProgress } from "react-loading-indicators";
import ReactPlayer from "react-player";
import { VideoPreviewer } from "../VideoPreviewer";
import styles from "./VideoPlayer.module.scss";

interface VideoPlayerProps {
  url: string;
  preview: string;
  isAutoPlay?: boolean;
  isLoading?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  preview,
  isAutoPlay = false,
  isLoading = false,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [isShowingVideo, setIsShowingVideo] = useState(isAutoPlay);
  const [isReady, setIsReady] = useState(false);

  const handlePlay = useCallback(() => {
    setIsShowingVideo(true);
  }, []);

  return (
    <div>
      {!isShowingVideo || isLoading ? (
        <VideoPreviewer url={preview} onClick={handlePlay} />
      ) : (
        <div className={styles.playerWrpper}>
          {!isReady && (
            <div className={styles.loader}>
              <OrbitProgress
                color="#0bb197"
                size="medium"
                text=""
                textColor=""
              />
            </div>
          )}
          <ReactPlayer
            key={`${isShowingVideo}`}
            onReady={() => setIsReady(true)}
            playing={isShowingVideo && isReady}
            controls
            width={"100%"}
            height={"100%"}
            url={url}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
                hlsOptions: {
                  maxBufferLength: 10,
                  maxMaxBufferLength: 10,
                  lowLatencyMode: true,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
export default memo(VideoPlayer);
