import classnames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import Course from "src/models/analitics/Course";
import { GroupAnalytic } from "src/models/analitics/LocationAnalytics";
import AccordionTable from "./AccordionTable";

interface MyAccordionProps {
  data: GroupAnalytic[];
  courses: Course[];
  currentPage: number;
  itemsPerPage: number;
  setCompleted: (courseId: number, personId: number, groupId: number) => void;
}

const MyAccordion: FC<MyAccordionProps> = ({
  data,
  currentPage,
  itemsPerPage,
  courses,
  setCompleted,
}) => {
  console.log(toJS(data), "asdasd");

  const [activeTabs, setActiveTabs] = useState(
    // Создаем массив с начальными значениями состояния, где первый элемент true, а остальные false
    Array(data.length)
      .fill(false)
      .map((value, index) => index === 0)
  );

  const toggleAccordion = (index: number) => {
    const newActiveTabs = [...activeTabs];
    newActiveTabs[index] = !newActiveTabs[index];
    setActiveTabs(newActiveTabs);
  };

  const handleSetCompleted = useCallback(
    (courseId: number, personId: number, groupId: number) => {
      setCompleted(courseId, personId, groupId);
    },
    [setCompleted]
  );

  return (
    <React.Fragment>
      {data
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map((item, index) => (
          <Card key={index} className="mb-3">
            <CardBody>
              <div className="accordion" id={`accordion-${index}`}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading-${index}`}>
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !activeTabs[index],
                      })}
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </button>
                  </h2>

                  <Collapse
                    isOpen={activeTabs[index]}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <AccordionTable
                        setCompleted={(...rest) =>
                          handleSetCompleted(...rest, item.id)
                        }
                        courses={courses}
                        groupId={item.id}
                        data={item.users}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
    </React.Fragment>
  );
};

export default observer(MyAccordion);
