import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useState } from "react";
import { AppModal } from "src/components/Common/Modal";
import { Page } from "src/components/complexes/Page";
import { UserRoles } from "src/models/profile/UserRoles";
import { AddCommentContext, PaymentContext } from "./Context";
import PaymentRateViewModel from "./PaymentRateViewModel";
import MainTable from "./parts/MainTable";
interface PaymentRateProps {}

const links = [
  { path: "/", label: "Главная" },
  { path: "/finance", label: "Финансы" },
  { path: `/finance/paymentRate`, label: "Норма прибыли" },
];
const PaymentRate: FC<PaymentRateProps> = ({}) => {
  const { setComment, comment, saveComment } = PaymentRateViewModel;
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const handleCloseMenu = useCallback(() => {
    setIsVisibleMenu(false);
  }, []);

  const handleSaveComment = useCallback(() => {
    saveComment();
    setIsVisibleModal(false);
  }, [saveComment]);

  return (
    <PaymentContext.Provider value={[isVisibleMenu, setIsVisibleMenu]}>
      <AddCommentContext.Provider value={[isVisibleModal, setIsVisibleModal]}>
        <div onClick={handleCloseMenu} className={classnames([])}>
          <Page
            accessRoles={[UserRoles.SUPERADMIN]}
            replaceNveLink="/analytics"
            links={links}
          >
            <MainTable />
          </Page>
        </div>
        <AppModal
          title="Комментарий"
          isOpen={isVisibleModal}
          onClose={() => setIsVisibleModal(false)}
          onSubmit={handleSaveComment}
        >
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="form-control"
          />
        </AppModal>
      </AddCommentContext.Provider>
    </PaymentContext.Provider>
  );
};
export default observer(PaymentRate);
