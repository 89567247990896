import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import ChartTable from "../table/chartTable";

const RadialChartData = ({ data, analyticsData }) => {
  function findName(index) {
    // Проверяем, существует ли data и data.courses_result
    if (
      data &&
      data.courses_result &&
      data.courses_result[index] &&
      data.courses_result[index].course_id
    ) {
      const name = analyticsData.courses.find(
        (course) => course.id === data.courses_result[index].course_id
      );
      if (name) {
        return name.name;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  const loyalty = data && data.loyalty ? data.loyalty : 0;
  const firstCourseValue =
    data && data.courses_result && data.courses_result[0]
      ? data.courses_result[0].value
      : 0;
  const secondCourseValue =
    data && data.courses_result && data.courses_result[1]
      ? data.courses_result[1].value
      : 0;

  return {
    series: [loyalty, firstCourseValue, secondCourseValue],
    options: {
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "18px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Лояльность",
              formatter: function (w) {
                return loyalty + "%";
              },
            },
          },
        },
      },
      labels: ["Лояльность", findName(0), findName(1)],
      colors: ["#34c38f", "#556ee6", "#f46a6a"],
    },
  };
};

const RadialChart = ({ dataChart, analyticsData }) => {
  const chartData = RadialChartData({ data: dataChart, analyticsData });

  const router = useNavigate();

  const handleCardClick = () => {
    router(`/analytics/${dataChart.id}`);
  };

  return (
    <Col onClick={handleCardClick} style={{ cursor: "pointer" }}>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">{dataChart.name}</CardTitle>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="radialBar"
            height="250"
            className="apex-charts"
          />
          <ChartTable
            analyticsData={analyticsData}
            loyalty={dataChart.loyalty}
            courses_result={dataChart.courses_result}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

RadialChart.propTypes = {
  dataChart: PropTypes.shape({
    series: PropTypes.array,
  }).isRequired,
};

export default observer(RadialChart);
