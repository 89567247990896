import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { VideoPlayer } from "src/components/Common/VideoPlayer";
import { VideoPreviewer } from "src/components/Common/VideoPreviewer";
import Location, { Group } from "src/models/location/Location";
import Record from "src/models/records/Record";
import LocationsService from "src/services/LocationsService";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MyLoaderChart from "../../components/Common/Loader/MyLoderChart";
import MyFiltersCameras from "../../components/Common/MyFilter/MyFiltersCameras";
import MySlideBar from "../../components/Common/SlideBar/MySlideBar";
import styles from "./CameraClass.module.scss";
import OnLineButton from "./parts/OnLineButton";

// Страница записей в группе
const CamerasClass = () => {
  const params = useParams();
  document.title = `Мои камеры | ${params.id} | ${params.class}`;

  const { locations, getLocations } = LocationsService;

  const loading = useSelector((state: any) => state.camerasReducer.loading);
  const error = useSelector((state: any) => state.camerasReducer.error);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStreamPlaying, setIsStreamPlaying] = useState(true);

  const camerasFilterData = useSelector(
    (state: any) => state.camerasReducer.filterCamerasData
  );
  const loadingFilter = useSelector(
    (state: any) => state.camerasReducer.loadingFilter
  );
  const errorFilter = useSelector(
    (state: any) => state.camerasReducer.errorFilter
  );

  const [activeVideo, setActiveVideo] = useState(
    camerasFilterData?.data?.records[0]
  );

  const activeLocation = useMemo(() => {
    return locations.find(
      (location: Location) => params.id && location.id === +params.id
    );
  }, [locations, params.id]);

  const links = useMemo(() => {
    return [
      { label: "Мои камеры" },
      { label: `${activeLocation?.name}`, path: `/cameras/${params.id}` },
      { label: `${params.class}` },
    ];
  }, [activeLocation?.name, params.class, params.id]);

  useEffect(() => {
    if (!locations.length) {
      getLocations();
    }
  }, []);

  let locationId;
  let choousedGroup;
  let noChoousedGroups;

  if (locations && locations.length > 0) {
    locationId = locations.find(
      (cameras: Location) => params.id && cameras.id === +params.id
    );
    if (locationId) {
      choousedGroup = locationId.groups.find(
        (group: Group) => group?.name === params.class
      );
      noChoousedGroups = locationId.groups.filter(
        (group: Group) =>
          group.name !== params.class && group.name !== "Все камеры"
      );
    }
  }

  const router = useNavigate();
  const handleCardClick = (myClass: any) => {
    router(`/cameras/${params.id}/${myClass}`);
  };

  const handlePressVideos = (record: Record) => {
    setIsStreamPlaying(false);
    setIsPlaying(true);
    setActiveVideo(record);
  };

  useEffect(() => {
    setActiveVideo(camerasFilterData?.data?.records[0]);
  }, [camerasFilterData?.data?.records]);

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid={true}>
          <Breadcrumbs links={links} />
          <Row>
            {loading ? (
              [...Array(9)].map((_, index) => (
                <Col key={index} xl={4} lg={6} md={6}>
                  <MyLoaderChart />
                </Col>
              ))
            ) : error ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных</Alert>
              </Col>
            ) : (
              <React.Fragment>
                <Col xl={9} lg={12}>
                  <MyFiltersCameras
                    leftElement={
                      <OnLineButton
                        isOnline={isStreamPlaying}
                        onClick={() => {
                          setIsStreamPlaying((prev) => !prev);
                        }}
                      />
                    }
                    selectedGroup={choousedGroup}
                  />
                  <Card>
                    <CardBody>
                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                        <VideoPlayer
                          isLoading={loading}
                          key={`${isPlaying}`}
                          isAutoPlay={isStreamPlaying || isPlaying}
                          url={
                            isStreamPlaying
                              ? choousedGroup?.cameras[0].stream
                              : activeVideo?.url ||
                                camerasFilterData?.data?.records[0]?.url ||
                                ""
                          }
                          preview={
                            activeVideo?.preview ||
                            choousedGroup?.cameras[0].image ||
                            camerasFilterData?.data?.records[0]?.preview ||
                            ""
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3}>
                  <MySlideBar>
                    {loadingFilter ? (
                      [...Array(9)].map((_, index) => (
                        <Col key={index}>
                          <div>
                            <div
                              style={{ height: "17vh" }}
                              className="card-text placeholder-glow mt-4 "
                            >
                              <span
                                style={{ height: "100%", borderRadius: 7 }}
                                className="placeholder col-12"
                              ></span>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : errorFilter ? (
                      <Col>
                        <Alert color="danger">Ошибка загрузки данных</Alert>
                      </Col>
                    ) : camerasFilterData &&
                      camerasFilterData.data &&
                      camerasFilterData.data.records &&
                      camerasFilterData.data.records.length > 0 ? (
                      camerasFilterData.data.records.map((player: Record) => (
                        <div
                          style={{ cursor: "pointer" }}
                          // onClick={() => handleCardClick(player.name)}
                          key={player.id}
                        >
                          <Card
                            style={{
                              boxShadow: "0 0px 0px rgba(0, 0, 0, 0.08)",
                            }}
                            className={classnames([
                              {
                                [styles.activeCard]:
                                  activeVideo?.id === player.id,
                              },
                              "mb-0",
                            ])}
                          >
                            <CardBody className="p-1">
                              <CardTitle
                                className={classnames("mb-1", {
                                  [styles.activeVideo]:
                                    activeVideo?.id === player.id,
                                })}
                              >
                                <span
                                  className={classnames("mb-1", {
                                    [styles.activeVideo]:
                                      activeVideo?.id === player.id,
                                  })}
                                >
                                  {" "}
                                  {player.dateStart.slice(10, -3)} -
                                  {player.dateEnd.slice(10, -3)}
                                </span>
                              </CardTitle>
                              <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                                <VideoPreviewer
                                  onClick={() => handlePressVideos(player)}
                                  url={player.preview || ""}
                                />
                              </div>
                            </CardBody>
                          </Card>
                          <hr
                            className="my-3"
                            style={{ borderColor: "#dee2e6" }}
                          />
                        </div>
                      ))
                    ) : (
                      <div>Нет данных</div>
                    )}
                  </MySlideBar>
                </Col>
              </React.Fragment>
            )}
          </Row>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CamerasClass;
