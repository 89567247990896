import { ColumnOption } from "src/Pages/Finance/PaymentRate/parts/types";
import UserListItem from "src/models/users/UserListItem";

export interface IUserColumn extends ColumnOption {
  value: keyof UserListItem;
}
export const __UsersTablesColumns: IUserColumn[] = [
  {
    label: "",
    className: "text-bold disabled",
    isRequired: true,
    isSortable: false,
    value: "isSelected",
  },
  {
    label: "#",
    className: "text-bold disabled",
    isRequired: true,
    isSortable: true,
    value: "id",
  },
  {
    label: "Локация",
    className: "text-bold disabled",
    isSortable: false,
    value: "location",
  },
  {
    label: "Имя",
    className: "text-bold disabled",
    isRequired: true,
    isSortable: true,
    value: "name",
  },
  {
    label: "Телефон",
    className: "text-bold",
    isSortable: false,
    value: "phone",
  },

  {
    label: "Баланс",
    className: "text-bold",
    isSortable: true,
    value: "balance",
  },
  {
    label: "Блокировка",
    className: "text-bold",
    isSortable: true,
    value: "block",
  },
  {
    label: "Статус",
    className: "text-bold",
    isSortable: true,
    value: "status",
  },
  {
    label: "Осталось дней",
    className: "text-bold",
    isSortable: true,
    value: "days_left",
  },
  {
    label: "Триал",
    className: "text-bold",
    isSortable: false,
    value: "trial",
  },
  {
    label: "Тариф",
    className: "text-bold",
    isSortable: false,
    value: "rate",
  },
  {
    label: "Дейстия",
    className: "text-bold",
    isSortable: false,
    value: "id",
    isRequired: true,
  },
];
