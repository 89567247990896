import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import ProgressbarSmall from "src/components/Common/Progressbar/ProgressbarSmall";
import Course from "src/models/analitics/Course";
import {
  CourseResult,
  UserAnalytic,
} from "src/models/analitics/LocationAnalytics";
import { UserRoles } from "src/models/profile/UserRoles";
import ProfileService from "src/services/ProfileService";

const getActualCourseValue = (
  coursesResult: CourseResult[],
  courseId: number
) => {
  const actualValue = coursesResult.find(
    (course) => course.course_id === courseId
  );

  return actualValue ? actualValue.value : 20;
};

interface AccordionTableProps {
  data: UserAnalytic[];
  courses: Course[];
  setCompleted: (courseId: number, personId: number) => void;
  groupId: number;
}
const AccordionTable: FC<AccordionTableProps> = ({
  data,
  courses,
  setCompleted,
  groupId,
}) => {
  const { role } = ProfileService;
  const router = useNavigate();
  const params = useParams();

  return (
    <div className="tablee-responsiv">
      <Col lg={12}>
        <table className="table mb-0">
          <thead>
            <tr>
              <th className="text-center">Пользователь</th>
              {courses.map((course, index) => (
                <th key={index} className="text-center">
                  {course.name}
                </th>
              ))}
              <th className="text-center">Действия</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((person, index) => (
                <tr key={index}>
                  <td className="text-center">{person.name || "none"}</td>
                  <td className="text-center">
                    <ProgressbarSmall
                      series={getActualCourseValue(
                        person.courses_result,
                        courses[0].id
                      )}
                    />
                    {role === UserRoles.SUPERADMIN &&
                      getActualCourseValue(
                        person.courses_result,
                        courses[0].id
                      ) < 100 && (
                        <div className="mt-2">
                          <button
                            onClick={() =>
                              setCompleted(courses[0].id, person.id)
                            }
                            className="btn btn-outline-primary"
                          >
                            Сделать пройденным
                          </button>
                        </div>
                      )}
                  </td>
                  <td className="text-center">
                    <ProgressbarSmall
                      series={getActualCourseValue(
                        person.courses_result,
                        courses[1].id
                      )}
                    />
                    {role === UserRoles.SUPERADMIN &&
                      getActualCourseValue(
                        person.courses_result,
                        courses[1].id
                      ) < 100 && (
                        <div className="mt-2">
                          <button
                            onClick={() =>
                              setCompleted(courses[1].id, person.id)
                            }
                            className="btn btn-outline-primary"
                          >
                            Сделать пройденным
                          </button>
                        </div>
                      )}
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() =>
                        router(
                          `/analytics/${params.id}/${groupId}/${person.name}`
                        )
                      }
                      type="button"
                      className="btn btn-outline-secondary waves-effect waves-light "
                    >
                      Подробнее
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </div>
  );
};

export default observer(AccordionTable);
