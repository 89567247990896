import React, { FC, memo, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";

export interface TabOption {
  label: string;
  href: string;
  icon?: string;
  isDefault?: boolean;
}
interface AppTabItemProps {
  option: TabOption;
}

const AppTabItem: FC<AppTabItemProps> = ({ option }) => {
  const [searchParams] = useSearchParams();
  const isActive = useMemo(() => {
    const tabName = searchParams.get("tab");
    if (!tabName && option.isDefault) return true;
    return option.href.split("=")[1] === tabName;
  }, [option.href, option.isDefault, searchParams]);
  return (
    <li className="">
      <Link
        className={`nav-link ${isActive ? "active" : ""}`}
        data-bs-toggle="tab"
        to={option.href}
      >
        <i className={option.icon}></i>{" "}
        <span className="d-none d-md-inline-block">{option.label}</span>
      </Link>
    </li>
  );
};
export default memo(AppTabItem);
