import React, { FC } from "react";

interface BottomDataItemProps {
  label: string;
  value: number;
  color: string;
  isPositive?: boolean;
  differentValue?: number;
}

const BottomDataItem: FC<BottomDataItemProps> = ({
  label,
  value,
  color,
  isPositive = true,
  differentValue = 0,
}) => {
  return (
    <div className="col-3 border-end">
      <div>
        <p className="mb-2">
          <i style={{ color }} className="mdi mdi-circle font-size-12 me-1"></i>
          {label}
        </p>
        <h5 className="font-size-16 mb-0">
          {value}{" "}
          <span
            className={`${
              differentValue > 0
                ? "text-success"
                : differentValue === 0
                ? "text-warning"
                : "text-danger"
            } font-size-12`}
          >
            <i
              className={`${
                differentValue > 0
                  ? "mdi-menu-up"
                  : differentValue === 0
                  ? "mdi-minus"
                  : " mdi-menu-down"
              } mdi font-size-14 me-1`}
            ></i>
            {differentValue} %
          </span>
        </h5>
      </div>
    </div>
  );
};
export default BottomDataItem;
