import { makeAutoObservable, runInAction } from "mobx";
import IDashboard from "src/models/dashboard/IDashboard";
import api from "src/services/ApiService";

let cacheDashboard: IDashboard | undefined = undefined;

class DashboardViewModel {
  constructor() {
    makeAutoObservable(this);
  }
  dashboardData?: IDashboard = cacheDashboard;
  isLoading = false;
  get history() {
    return (
      this.dashboardData?.payment_history || {
        month: [],
        half_year: [],
        year: [],
      }
    );
  }

  get activeUsersData() {
    return (
      this.dashboardData?.active_users || {
        now: 0,
        today: 0,
        time_avg: 0,
        time_max: 0,
        today_max: 0,
        now_max: 0,
      }
    );
  }

  getDashboardData = async () => {
    this.isLoading = true;
    const response = await api.get("/dashboard");
    runInAction(() => {
      this.dashboardData = response.data;
      cacheDashboard = response.data;
      this.isLoading = false;
    });
  };
}

export default DashboardViewModel;
