import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { AppCheckbox } from "src/components/Common/AppCheckbox";
import { OptionModel } from "src/models/common/FilterModel";
import styles from "./UsersTableHeader.module.scss";

interface UsersTableHeaderProps {
  options: OptionModel[];
  onSort: (e: OptionModel) => void;
  colLabel?: string;
  isAsc?: boolean;
  onSelectAll: () => void;
  isSelectedAll: boolean;
}

const UsersTableHeader: FC<UsersTableHeaderProps> = ({
  options,
  onSort,
  colLabel,
  isAsc,
  onSelectAll,
  isSelectedAll,
}) => {
  const handleSort = (e: OptionModel) => {
    onSort(e);
  };
  return (
    <thead
      style={{
        display: "table-header-group",
      }}
    >
      <tr>
        {options.map((option, index) => (
          <th
            style={{
              position: "sticky",
            }}
            onClick={() => option.isSortable && handleSort(option)}
            colSpan={option.extraData?.countOfColumn || 1}
            key={index}
            className={classNames({
              [styles.sorted]: !!option.isSortable,
              [styles.asc]: option.value === colLabel && !!isAsc,
              [styles.desc]: option.value === colLabel && !isAsc,
            })}
          >
            <div
              className={classNames({
                [styles.withSort]: !!option.isSortable,
              })}
            >
              {option.value !== "isSelected" ? (
                option.label
              ) : (
                <AppCheckbox
                  value={"all"}
                  isChecked={isSelectedAll}
                  onClick={onSelectAll}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default observer(UsersTableHeader);
