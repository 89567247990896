import React, { FC } from "react";

interface AppSwitchProps {
  isChecked?: boolean;
  onClick: () => void;
}

const AppSwitch: FC<AppSwitchProps> = ({ onClick, isChecked }) => {
  return (
    <div className="form-check form-switch">
      <label className="form-check-label">
        <input
          onChange={onClick}
          checked={isChecked}
          className="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
        />
      </label>
    </div>
  );
};
export default AppSwitch;
