import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Alert, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import { observer } from "mobx-react-lite";
import { useParams, useSearchParams } from "react-router-dom";
import LocationAnalytics, {
  GroupAnalytic,
} from "src/models/analitics/LocationAnalytics";
import LocationsService from "src/services/LocationsService";
import { useFilter } from "../../Hooks/useFilter";
import usePagination from "../../Hooks/usePagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MyLoderChart from "../../components/Common/Loader/MyLoderChart";
import MyFilter, { Filter } from "../../components/Common/MyFilter/MyFilter";
import Pagination from "../../components/Common/ui/Pagination";
import AnalyticsViewModel from "./AnalyticsViewModel";
import MyAccordion from "./parts/MyAccordion";

const AnalyticsClass: FC = () => {
  const {
    locationAnalytics,
    isLoading,
    getAnalyticsData,
    setCompleted,
    courseAnalytics,
    isError,
  } = AnalyticsViewModel;
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSort = useMemo(() => {
    return searchParams.get("sort") || "loyalty";
  }, [searchParams]);

  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filter, setFilter] = useState({ sort: defaultSort, query: "" });

  //камеры
  const { locations, getLocations } = LocationsService;

  const selectedData = useMemo(() => {
    if (locationAnalytics) {
      return locationAnalytics.find(
        // @ts-ignore
        (data: LocationAnalytics) => data.id == params.id
      );
    }
  }, [params.id, locationAnalytics]);

  // let selectedData: LocationAnalytics | undefined;

  // if (locationAnalytics) {
  //   selectedData = locationAnalytics.find(
  //     (data: LocationAnalytics) => data.name === params.id
  //   );
  // }
  const sortedAndSearchedCharts: GroupAnalytic[] = useFilter(
    selectedData?.groups || [],
    filter.sort,
    filter.query
  );

  useEffect(() => {
    if (locationAnalytics.length === 0 && !isLoading) {
      getAnalyticsData();
    }
    if (locations.length === 0) {
      // getLocations();
    }
  }, [isLoading, locationAnalytics.length, locations.length]);

  const options = [
    { value: "name", name: "По названию" },
    { value: "loyalty", name: "По лояльности" },
  ];

  //brandcrumb
  const links = useMemo(() => {
    const groupName = selectedData?.groups.find(
      // @ts-ignore
      (group: GroupAnalytic) => group.id == params.class
    );
    return [
      { path: "/", label: "Главная" },
      { path: "/analytics", label: "Аналитика" },
      { path: `/analytics/${params.id}`, label: selectedData?.name },
      { label: groupName?.name || params.class },
    ];
  }, [params.class, params.id, selectedData?.groups, selectedData?.name]);

  //Данные передаваемые в аккордион

  const accordionData = useMemo(() => {
    return [...(sortedAndSearchedCharts || [])];
  }, [sortedAndSearchedCharts]);

  // Перемещаем элемент с полем name равным params.class на первую позицию массива
  const firstItemIndex = accordionData.findIndex(
    // @ts-ignore
    (item) => item.id === params.class
  );
  if (firstItemIndex !== -1) {
    const firstItem = accordionData.splice(firstItemIndex, 1)[0];
    accordionData.unshift(firstItem);
  }

  //пагинация
  const { currentPage, totalPages, handlePageChange } = usePagination(
    accordionData.length,
    itemsPerPage
  );

  const onChangeSetParams = useCallback((sort: string) => {
    setFilter({ sort, query: "" });
  }, []);

  useLayoutEffect(() => {
    const page = searchParams.get("page") || 1;

    handlePageChange(page);
  }, [handlePageChange, onChangeSetParams, searchParams]);

  const handleChangeFilter = useCallback(
    (filter: Filter) => {
      setSearchParams({
        sort: filter.sort,
      });
      setFilter(filter);
    },
    [setSearchParams]
  );

  document.title = `Аналитика | ${selectedData?.name} | ${params.class} `;

  return (
    <React.Fragment>
      <div className="page-content d-flex flex-column mb-4">
        <Container fluid={true}>
          <Breadcrumbs links={links} />
          <Row>
            {isLoading ? (
              [...Array(6)].map((_, index) => (
                <Col key={index} xl={4} lg={6} md={6}>
                  <MyLoderChart />
                </Col>
              ))
            ) : isError ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных аналитики</Alert>
              </Col>
            ) : (
              <div
                className="flex-grow-1 d-flex flex-column"
                style={{ minHeight: "85vh" }}
              >
                <MyFilter
                  filter={filter}
                  setFilter={handleChangeFilter}
                  options={options}
                  pageValue={itemsPerPage}
                  onChangePage={(value: number) => setItemsPerPage(value)}
                />

                {accordionData.length === 0 ? (
                  <h3>Ничего не найдено!</h3>
                ) : (
                  <MyAccordion
                    data={accordionData}
                    courses={courseAnalytics}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setCompleted={(...rest) =>
                      setCompleted(...rest, selectedData?.id || 0)
                    }
                  />
                )}
                <div className="mt-auto">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default observer(AnalyticsClass);
