import { ColumnOption, TableData } from "./types";

export const __TablesMainColumns: ColumnOption[] = [
  {
    label: "Локация",
    className: "text-bold disabled",
    isRequired: true,
    isSortable: true,
  },
  {
    label: "Локаций",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Активных",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Емкость",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Регистраций",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Модераций",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Активаций",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Баланс +",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Платящие",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Возраст",
    className: "text-bold",
    isSortable: true,
  },
  {
    label: "Комментарий",
    className: "text-bold",
    isSortable: false,
  },
];

export const __MOCK_TABLE_DATA: TableData = {
  items: [
    {
      id: "1",
      locationName: "Москва",
      className: "odd",
      data: __TablesMainColumns.map((item, index) => ({
        ...item,
        isVisible: false,
        value: index === 10 ? "" : 10 + index,
      })),
      items: [
        {
          id: "2",
          locationName: "School 1",
          className: "even",
          data: __TablesMainColumns.map((item, index) => ({
            ...item,
            isVisible: false,
            value: index === 10 ? "" : 10 + index,
          })),
          items: [
            {
              id: "3",
              locationName: "Korpus 1",
              className: "even",
              data: __TablesMainColumns.map((item, index) => ({
                ...item,
                isVisible: true,
                value: index === 10 ? "" : 10 + index,
              })),
              items: [
                {
                  id: "4",
                  locationName: "group 1",
                  className: "odd",
                  data: __TablesMainColumns.map((item, index) => ({
                    ...item,
                    isVisible: true,
                    value: index === 10 ? "" : 10 + index,
                  })),
                  items: [
                    {
                      id: "1-2-3-4-5",
                      locationName: "User 1asdas",
                      className: "even",
                      data: __TablesMainColumns.map((item, index) => ({
                        ...item,
                        isVisible: true,
                        value: index === 10 ? "" : 10 + index,
                        isEditable: true,
                      })),
                      items: [],
                    },
                  ],
                },
                {
                  id: "1-2-3-5",
                  locationName: "group 2",
                  className: "even",
                  data: __TablesMainColumns.map((item, index) => ({
                    ...item,
                    isVisible: true,
                    value: index === 10 ? "" : 10 + index,
                  })),
                  items: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "2",
      locationName: "Сочи",
      className: "odd",
      data: __TablesMainColumns.map((item, index) => ({
        ...item,
        isVisible: false,
        value: index === 10 ? "" : 20 + index,
      })),

      items: [
        {
          id: "2",
          locationName: "School 1333",
          className: "even",
          data: __TablesMainColumns.map((item, index) => ({
            ...item,
            isVisible: false,
            value: index === 10 ? "" : 10 + index,
          })),
          items: [
            {
              id: "3",
              locationName: "Korpus 1",
              className: "even",
              data: __TablesMainColumns.map((item, index) => ({
                ...item,
                isVisible: true,
                value: index === 10 ? "" : 10 + index,
              })),
              items: [
                {
                  id: "4",
                  locationName: "group 1",
                  className: "odd",
                  data: __TablesMainColumns.map((item, index) => ({
                    ...item,
                    isVisible: true,
                    value: index === 10 ? "" : 10 + index,
                  })),
                  items: [
                    {
                      id: "2-2-3-4-5",
                      locationName: "User 1asdas",
                      className: "even",
                      data: __TablesMainColumns.map((item, index) => ({
                        ...item,
                        isVisible: true,
                        value: index === 10 ? "" : 10 + index,
                        isEditable: true,
                      })),
                      items: [],
                    },
                  ],
                },
                {
                  id: "5",
                  locationName: "group 2",
                  className: "even",
                  data: __TablesMainColumns.map((item, index) => ({
                    ...item,
                    isVisible: true,
                    value: index === 10 ? "" : 10 + index,
                  })),
                  items: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
