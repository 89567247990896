import { makeAutoObservable, runInAction } from "mobx";
import Profile from "src/models/profile/Profile";
import api from "./ApiService";

class ProfileService {
  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false;
  profile?: Profile = undefined;

  get role() {
    return this.profile?.role;
  }

  getProfile = async () => {
    this.isLoading = true;
    const response = await api.get("/user"); //получение профиля из $api
    runInAction(() => {
      this.isLoading = false;
      console.log(response.data);

      this.profile = response.data.user;
    });
  };
}

export default new ProfileService();
