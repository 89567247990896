import React, { FC } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import styles from "./HeaderPercentMeterItem.module.scss";

interface HeaderPercentMeterItemProps {
  value: number;
  label: string;
  differentValue: number;
  isPositive?: boolean;
  absoluteValue: number | string;
}

let radialoptions: any = {
  chart: {
    width: 72,
    height: 72,
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#0ab39c"],
  stroke: {
    lineCap: "round",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "70%",
      },
      track: {
        margin: 0,
      },

      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: 5,
          show: true,
        },
      },
    },
  },
};

const HeaderPercentMeterItem: FC<HeaderPercentMeterItemProps> = ({
  value,
  label,
  differentValue,
  isPositive,
  absoluteValue,
}) => {
  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex text-muted">
            <div className="flex-shrink-0 me-3 align-self-center">
              <ReactApexChart
                series={[value]}
                options={radialoptions}
                type="radialBar"
                width="75"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1">{label}</p>
              <h5 className="mb-3">{absoluteValue}</h5>
              <p className="text-truncate mb-0">
                <span
                  className={`${
                    differentValue > 0
                      ? "text-success"
                      : differentValue === 0
                      ? "text-warning"
                      : "text-danger"
                  } text-success me-2`}
                >
                  {" "}
                  {differentValue}{" "}
                  <i
                    className={`ri ${
                      differentValue > 0
                        ? "ri-arrow-right-up-line"
                        : differentValue === 0
                        ? "ri-arrow-right-line"
                        : "ri-arrow-right-down-line"
                    }  align-bottom ms-1`}
                  ></i>
                </span>{" "}
                от предыдущего
              </p>
            </div>
          </div>
          <div className={styles.wrapper}>
            {/* <Row>
              <Col xl={4} lg={4} md={3} xs={3}>
                <div
                  className={classnames(["flexCenter", styles.percentBlock])}
                >
                  <ReactApexChart
                    series={[value]}
                    options={radialoptions}
                    type="radialBar"
                    width="75"
                  />
                </div>
              </Col>
              <Col xl={8} lg={8} md={9} xs={9}>
                <Row>
                  <CardTitle>{label}</CardTitle>
                </Row>
                <Row>
                  <CardText>{differentValue} % за 30 дней</CardText>
                </Row>
              </Col>
            </Row> */}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default HeaderPercentMeterItem;
