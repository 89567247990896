import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { OptionModel } from "src/models/common/FilterModel";
import styles from "../PaymentRate.module.scss";

interface TablesHeaderProps {
  options: OptionModel[];
  onSort: (e: OptionModel) => void;
  colLabel?: string;
  isAsc?: boolean;
}

const TablesHeader: FC<TablesHeaderProps> = ({
  options,
  onSort,
  colLabel,
  isAsc,
}) => {
  const handleSort = (e: OptionModel) => {
    onSort(e);
  };
  return (
    <thead
      style={{
        display: "table-header-group",
      }}
    >
      <tr>
        {options.map((option, index) => (
          <th
            style={{
              position: "sticky",
            }}
            onClick={() => handleSort(option)}
            colSpan={option.extraData?.countOfColumn || 1}
            key={index}
            className={classnames({
              [styles.sorted]: !!option.isSortable,
              [styles.asc]: option.label === colLabel && !!isAsc,
              [styles.desc]: option.label === colLabel && !isAsc,
            })}
          >
            {option.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default observer(TablesHeader);
