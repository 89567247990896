import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { AppNavTabs } from "src/components/Common/AppNavTabs";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import styles from "./UserWizard.module.scss";
import UserWizardViewModel from "./UserWizardViewModel";
import { ProfileTab } from "./parts/ProfileTab";

interface UserWizardProps {}

const UserWizard: FC<UserWizardProps> = ({}) => {
  const { id } = useParams();

  const vm = useMemo(() => new UserWizardViewModel(id), [id]);
  const { currentUser } = vm;

  const links = useMemo(
    () => [
      { path: "/", label: "Главная" },
      { path: "/users", label: "Пользователи" },
      {
        path: `/users/${currentUser?.id}`,
        label: currentUser?.name || "Пользователь",
      },
    ],

    [currentUser?.id, currentUser?.name]
  );

  return (
    <div className={classNames("page-content mb-4", styles.container)}>
      <Container fluid={true}>
        <Breadcrumbs links={links} />
        <AppNavTabs
          options={[
            {
              label: "Профиль",
              href: `?tab=profile`,
              icon: "bx bx-user",
              isDefault: true,
            },
            {
              label: "Задачи",
              href: `?tab=tasks`,
              icon: "bx bx-task",
            },
            {
              label: "История",
              href: `?tab=history`,
              icon: "bx bx-history",
            },
            {
              label: "Войти в кабинет",
              href: `?tab=exit`,
              icon: "bx bx-exit",
            },
          ]}
        />
        <ProfileTab vm={vm} tabName={`profile`} isDefault />
      </Container>
    </div>
  );
};
export default observer(UserWizard);
