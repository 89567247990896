import React, { FC, memo } from "react";
import Log from "src/models/profile/Logs";
import styles from "../../UserWizard.module.scss";

interface LogListProps {
  logs?: Log[];
}

const LogList: FC<LogListProps> = ({ logs }) => {
  return (
    <div className={styles.logList}>
      {logs?.map((item, index) => (
        <div
          style={{
            backgroundColor: item.color,
          }}
          className={styles.log}
          key={index}
        >
          {item.description}
        </div>
      ))}
    </div>
  );
};
export default memo(LogList);
