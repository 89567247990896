import React, { FC, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { IPaymentHistory } from "src/models/dashboard/IDashboard";
import BottomDataItem from "./BottomDataItem";
import ButtonSelector from "./ButtonSelector";
import styles from "./MainChart.module.scss";
import { _CHART_PERIODS } from "./constants";
interface MainChartProps {
  paymentHistory: IPaymentHistory;
}

let options: any = {
  chart: {
    height: 350,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: [0, 1, 1],
    dashArray: [0, 0, 5],
    curve: "smooth",
  },
  plotOptions: {
    bar: {
      columnWidth: "18%",
    },
  },

  legend: {
    show: false,
  },

  fill: {
    opacity: [0.85, 0.25, 1],
    gradient: {
      inverseColors: false,
      shade: "light",
      type: "vertical",
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },
  markers: {
    size: 0,
  },
  colors: ["#0bb197", "#5ff2f7", "#ff3d60", "#a5734d"],
};
const MainChart: FC<MainChartProps> = ({ paymentHistory }) => {
  const [activePeriod, setActivePeriod] = useState(_CHART_PERIODS[0].value);

  const charData = useMemo(() => {
    const active = paymentHistory[activePeriod];
    const chartLabels = active.map((item) => `${item.label}`);
    const dataNew = {
      name: "Новые",
      type: "column",
      data: active.map((i) => i.new),
      extraData: {
        total: active.reduce((acc, i) => acc + i.new, 0),
        different: active.reduce((acc, i) => acc + i.new_diff, 0),
      },
    };
    const dataRepeat = {
      name: "Повторные",
      type: "column",
      data: active.map((i) => i.repeat),
      extraData: {
        total: active.reduce((acc, i) => acc + i.repeat, 0),
        different: active.reduce((acc, i) => acc + i.repeat_diff, 0),
      },
    };
    const dataUpcoming = {
      name: "Упущенные",
      type: "line",
      data: active.map((i) => i.missed),
      extraData: {
        total: active.reduce((acc, i) => acc + i.missed, 0),
        different: active.reduce((acc, i) => acc + i.missed_diff, 0),
      },
    };
    const dataPossible = {
      name: "Возможные",
      type: "column",
      data: active.map((i) => i.possible),
      extraData: {
        total: active.reduce((acc, i) => acc + i.possible, 0),
        different: active.reduce((acc, i) => acc + i.possible_diff, 0),
      },
    };
    return {
      chartLabels,
      data: [dataNew, dataRepeat, dataUpcoming, dataPossible],
    };
  }, [activePeriod, paymentHistory]);
  return (
    <Col xl={8}>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <CardTitle>История оплат</CardTitle>
            </div>
            <div className="flex-shrink-0">
              <div className={styles.btnWrapper}>
                {_CHART_PERIODS.map((item, key) => (
                  <ButtonSelector
                    label={item.label}
                    value={item.value}
                    isActive={activePeriod === item.value}
                    key={key}
                    onClick={setActivePeriod}
                  />
                ))}
              </div>
            </div>
          </div>
          <ReactApexChart
            options={{ ...options, labels: charData.chartLabels }}
            series={charData.data}
            height={350}
          />
        </CardBody>
        <CardBody className="border-top">
          <div className="text-muted text-center">
            <Row>
              {/* {_CHART_DATA[activePeriod].data.map((item, key) => (
                <BottomDataItem
                  key={item.name}
                  label={item.name.toString()}
                  value={0}
                  color={options.colors[key]}
                  differentValue="2%"
                  isPositive={key === 0}
                />
              ))} */}

              <BottomDataItem
                label={charData.data[0].name.toString()}
                value={charData.data[0].extraData.total}
                color={options.colors[0]}
                differentValue={charData.data[0].extraData.different}
              />
              <BottomDataItem
                label={charData.data[1].name.toString()}
                value={charData.data[1].extraData.total}
                color={options.colors[1]}
                differentValue={charData.data[1].extraData.different}
              />
              <BottomDataItem
                label={charData.data[2].name.toString()}
                value={charData.data[2].extraData.total}
                color={options.colors[2]}
                differentValue={charData.data[2].extraData.different}
              />
              <BottomDataItem
                label={charData.data[3].name.toString()}
                value={charData.data[3].extraData.total}
                color={options.colors[3]}
                differentValue={charData.data[3].extraData.different}
              />
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default MainChart;
