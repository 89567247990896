import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Group } from "src/models/location/Location";
import LocationsService from "src/services/LocationsService";
import { useFilter } from "../../Hooks/useFilter";
import usePagination from "../../Hooks/usePagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MyLoaderChart from "../../components/Common/Loader/MyLoderChart"; // Corrected import name
import MyFilter from "../../components/Common/MyFilter/MyFilter";
import { VideoPlayer } from "../../components/Common/VideoPlayer";
import Pagination from "../../components/Common/ui/Pagination";
import styles from "./CameraClass.module.scss";

// Страница локации с группами
const CamerasId = () => {
  const params = useParams();
  document.title = `Мои камеры | ${params.id}`;

  const { locations, getLocations, isLoading } = LocationsService;
  const loading = useSelector((state: any) => state.camerasReducer.loading);
  const error = useSelector((state: any) => state.camerasReducer.error);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filter, setFilter] = useState({ sort: "", query: "" });
  const links = useMemo(() => {
    const activeLocation = locations.find(
      (location) => `${location.id}` === params.id
    );
    return [
      { label: "Мои камеры" },
      { label: `${activeLocation?.name || ""}` },
    ];
  }, [locations, params.id]);
  useEffect(() => {
    if (!locations.length) {
      getLocations();
    }
  }, []);

  const router = useNavigate();
  const handleCardClick = (myClass: string) => {
    router(`/cameras/${params.id}/${myClass}`);
  };

  let camerasArrayPage;

  if (locations) {
    camerasArrayPage = locations.find(
      (cameras) => `${cameras.id}` === params.id
    );
  }

  const dataLocations = camerasArrayPage?.groups || [];
  const sortedAndSearchedCharts = useFilter(
    dataLocations,
    filter.sort,
    filter.query
  );

  const { currentPage, totalPages, handlePageChange } = usePagination(
    sortedAndSearchedCharts.length,
    itemsPerPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const setNewPage = useCallback((page: number) => {
    handlePageChange(page);
  }, []);

  useLayoutEffect(() => {
    handlePageChange(searchParams.get("page") || 1);
  }, [handlePageChange, searchParams]);

  const options = [{ value: "name", name: "По названию" }];

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid={true}>
          <Breadcrumbs links={links} />
          <Row>
            {loading ? (
              [...Array(9)].map((_, index) => (
                <Col key={index} xl={4} lg={6} md={6}>
                  <MyLoaderChart />
                </Col>
              ))
            ) : error ? (
              <Col>
                <Alert color="danger">Ошибка загрузки данных</Alert>
              </Col>
            ) : (
              <div
                className="flex-grow-1 d-flex flex-column"
                style={{ minHeight: "85vh" }}
              >
                <MyFilter
                  filter={filter}
                  setFilter={setFilter}
                  options={options}
                  pageValue={itemsPerPage}
                  onChangePage={(value) => setItemsPerPage(value)}
                />
                {sortedAndSearchedCharts.length === 0 ? (
                  <h3>{isLoading ? "Загрузка..." : "Ничего не найдено!"}</h3>
                ) : (
                  <Row>
                    {sortedAndSearchedCharts
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((group: Group) => (
                        <Col key={group.id} xl={4} lg={6} md={6}>
                          <Card
                            className={classnames({
                              [styles.card]: true,
                            })}
                          >
                            <CardBody>
                              <CardTitle
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCardClick(group.name)}
                              >
                                {group.name}
                              </CardTitle>
                              <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                                {group.cameras && group.cameras.length > 0 ? ( // Проверяем, существует ли group.cameras и не является ли пустым
                                  // <iframe
                                  //   title={group.id}
                                  //   id={group.id}
                                  //   className="embed-responsive-item"
                                  //   src={group.cameras[0].stream || ""}
                                  //   allowFullScreen
                                  //   allow="autoplay; fullscreen"
                                  // />
                                  <>
                                    <VideoPlayer
                                      url={group.cameras[0].stream}
                                      preview={group.cameras[0].image}
                                    />
                                  </>
                                ) : (
                                  // <Player
                                  //   playsInline
                                  //   poster={group.cameras[0].image || ""}
                                  //   src={group.cameras[0].stream || ""}
                                  // />
                                  <div>Нет данных</div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                )}
                <div className="mt-auto">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={setNewPage}
                  />
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default observer(CamerasId);
