import { UserRoles } from "./UserRoles";
// 0 - нет статуса
// 1 - регистрация
// 2 - отказано в модерации
// 3 - на модерации
// 4 - прошел модерацию
// 5 - отказ

export enum UserStatus {
  NO_STATUS = 0,
  REGISTRATION = 1,
  REJECTED = 2,
  MODERATION = 3,
  APPROVED = 4,
  DECLINE = 5,
}

export interface UserLocation {
  id: number;
  name: string;
  isMain: boolean;
  isActive: boolean;
}
export interface User {
  id: number;
  name: string;
  phone: string;
  status: UserStatus;
  role: UserRoles;
  locked: number;
  balance: number;
  timezone: string;
  trial: number;
  tariff: string;
  can_use_promocode: boolean;
  showTeacherBounty: boolean;
  demo: boolean;
  watermark: string;
  yandexCards: string[];
  locations: UserLocation[];
  restOfDays: number;
  isUnlimited: boolean;
  isSelected?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  isAutoPayment?: boolean;
}
