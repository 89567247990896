import React from "react";
import { useMediaQuery } from "react-responsive";
import { Col } from "reactstrap";

const ChartTable = ({ loyalty, courses_result, analyticsData }) => {
  // Определяем значение xl в зависимости от размера экрана
  const isLargeScreen = useMediaQuery({ minWidth: 1600 });

  const xlValue = isLargeScreen ? 12 : 11;

  function findName(index) {
    if (
      courses_result &&
      courses_result[index] &&
      courses_result[index].course_id
    ) {
      const name = analyticsData.courses.find(
        (cource) => cource.id == courses_result[index].course_id
      );
      if (name) {
        return name.name;
      } else {
        return "";
      }
    } else {
      return "-";
    }
  }

  // const adaptation = analyticsData.data.courses.find(
  //   (cours) => cours.name == "Адаптация"
  // );

  // const basic = analyticsData.data.courses.find(
  //   (cours) => cours.name == "Базовый курс"
  // );

  // //поиск результата курса по id
  // function findResultCours(id) {
  //   const resultBasic = courses_result.find(
  //     (result) => result.course_id === id
  //   );
  //   return resultBasic.value;
  // }

  return (
    <div className="tablee-responsiv ">
      <Col>
        <table className="table mb-0">
          <thead>
            <tr style={{ fontSize: xlValue }}>
              <th style={{ padding: 6 }} className="text-center">
                Лояльность
              </th>
              <th style={{ padding: 6 }} className="text-center">
                {findName(0)}
              </th>
              <th style={{ padding: 6 }} className="text-center">
                {findName(1)}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">{loyalty}%</td>
              <td className="text-center">
                {courses_result[0] ? courses_result[0].value : 0}%
              </td>
              <td className="text-center">
                {courses_result[1] ? courses_result[1].value : 0}%
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </div>
  );
};

export default ChartTable;
