import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Zoom, toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AppCheckbox } from "src/components/Common/AppCheckbox";
import { ModalMenu } from "src/components/Common/ModalMenu";
import Pagination from "src/components/Common/ui/Pagination";
import { FilterModel, OptionModel } from "src/models/common/FilterModel";
import UserListItem from "src/models/users/UserListItem";
import UsersListViewModel from "../UsersListViewModel";
import { __UsersTablesColumns } from "../constants";
import UsersTableHeader from "./UsersTableHeader";

interface UsersListProps {
  vm: UsersListViewModel;
}

const UsersList: FC<UsersListProps> = ({ vm }) => {
  const notify = () => toast("Wow so easy!");
  const [isOpenSelect, setIsOpenSelect] = React.useState(false);
  const currentMenu = useMemo(() => {
    return new FilterModel(
      __UsersTablesColumns.map(
        (item) =>
          new OptionModel(
            item.label,
            item.value,
            true,
            item.isRequired,
            item.isSortable
          )
      ),
      true
    );
  }, []);

  const handleFilter = (e: OptionModel) => {
    if (e.value === vm.sort_by) {
      vm.setSortFilter({
        sort_by: vm.sort_by,
        isAsc: vm.order_by === "ASC" ? false : true,
      });
    } else {
      vm.setSortFilter({
        sort_by: e.value as keyof UserListItem,
        isAsc: false,
      });
    }
  };

  const copyToClipboard = (el: HTMLElement | null) => {
    if (!el) return;
    let body = document.body,
      range,
      sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel?.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel?.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel?.addRange(range);
      }
      // @ts-ignore
    } else if (body.createTextRange) {
      // @ts-ignore
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
    document.execCommand("Copy");
    toast.success("Данные скопированы в буфер обмена", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      transition: Zoom,
    });
  };
  return (
    <div className="">
      <Row>
        <Col sm={12} md={6}>
          <div className="dt-buttons btn-group flex-wrap mb-2">
            <button
              onClick={() =>
                copyToClipboard(document.getElementById("datatable-buttons"))
              }
              className="btn btn-secondary buttons-copy buttons-html5"
              tabIndex={0}
              aria-controls="datatable-buttons"
              type="button"
            >
              <span>Копировать</span>
            </button>{" "}
            <button
              onClick={vm.getExcel}
              className="btn btn-secondary buttons-excel buttons-html5"
              tabIndex={0}
              aria-controls="datatable-buttons"
              type="button"
            >
              <span>Excel</span>
            </button>{" "}
            <div className="btn-group">
              <button
                className="btn btn-secondary buttons-collection dropdown-toggle buttons-colvis"
                tabIndex={0}
                aria-controls="datatable-buttons"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenSelect(!isOpenSelect);
                }}
              >
                {isOpenSelect && <ModalMenu filter={currentMenu} />}
                <span>Столбцы</span>
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {vm.isLoading ? (
          [...Array(30)].map((_, index) => (
            <Col key={index} xl={4} lg={6} md={6}>
              <div>
                <div
                  style={{ height: "30px" }}
                  className="card-text placeholder-glow mt-4 "
                >
                  <span
                    style={{ height: "100%", borderRadius: 7 }}
                    className="placeholder col-12"
                  ></span>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <Col xl={"12"}>
            <table
              id={"datatable-buttons"}
              tabIndex={0}
              className="table  table-bordered dt-responsive nowrap dataTable no-footer dtr-inline"
            >
              <UsersTableHeader
                colLabel={vm.sort_by}
                isAsc={vm.order_by === "ASC"}
                onSort={handleFilter}
                options={currentMenu.selectedOptions}
                isSelectedAll={vm.isSelectedAll}
                onSelectAll={vm.toggleAllUsers}
              />
              {!vm.isLoading && (
                <tbody>
                  {vm.users.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <TableCell
                          label={""}
                          value={"isSelected"}
                          options={currentMenu.selectedOptions}
                        >
                          <AppCheckbox
                            value={item.id}
                            isChecked={vm.selectedUsersIds.includes(item.id)}
                            onClick={() => {
                              vm.toggleUser(item.id);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          label={item.id}
                          value={"id"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.location || ""}
                          value={"location"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          value={"name"}
                          options={currentMenu.selectedOptions}
                        >
                          <NavLink to={`/users/${item.id}`}>
                            {item.name}
                          </NavLink>
                        </TableCell>
                        <TableCell
                          label={item.phone}
                          value={"phone"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.balance}
                          value={"balance"}
                          options={currentMenu.selectedOptions}
                        />

                        <TableCell
                          label={item.block}
                          value={"block"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.status}
                          value={"status"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.days_left}
                          value={"days_left"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.trial}
                          value={"trial"}
                          options={currentMenu.selectedOptions}
                        />
                        <TableCell
                          label={item.rate}
                          value={"rate"}
                          options={currentMenu.selectedOptions}
                        />
                        <td>
                          <div
                            className={classNames(
                              "d-flex justify-content-between"
                            )}
                          >
                            <div
                              className={classNames(
                                "d-flex justify-content-between"
                              )}
                            >
                              <i
                                style={{
                                  cursor: "pointer",
                                  color: "green",
                                  fontSize: 16,
                                }}
                                className="mdi mdi-exit-to-app"
                              ></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </Col>
        )}
      </Row>
      {!vm.isLoading && (
        <div className="mt-auto">
          <Pagination
            totalPages={vm.totalPages}
            currentPage={vm.page}
            onPageChange={vm.setPage}
          />
        </div>
      )}
    </div>
  );
};
export default observer(UsersList);

const TableCell: FC<{
  label?: string | number;
  value: keyof UserListItem;
  options: OptionModel[];
  children?: ReactNode;
}> = observer(({ label, value, options, children }) => {
  const isSelected = useMemo(() => {
    const hasOption = options.find((item) => item.value === value);
    return !!hasOption;
  }, [options, value]);

  return (
    <td
      style={{
        display: isSelected ? "table-cell" : "none",
      }}
    >
      <div className={classNames("d-flex justify-content-between")}>
        <div className={classNames("d-flex justify-content-between")}>
          {!!label && <div className={"font-weight-bold"}>{label}</div>}
          {children && <div>{children}</div>}
        </div>
      </div>
    </td>
  );
});
